import styled from "styled-components";
import Mijosa from "~/assets/images/mijosa.png";

export const MainMiJosaImage = styled.img.attrs({ src: `${Mijosa}` })`
  width: 33rem;
  height: 25rem;
  border-radius: 1rem;

  //media query 스타일이 1073px미만일때 적용되게 됩니다.
  @media screen and (max-width: 1023px) {
    width: 100%;
    height: 13rem;
  }
`;
