import styled from "styled-components";

export const FooterText = styled.div`
  font-family: "PretendardRegular";
  font-size: 0.9rem;
  line-height: 1.5rem;
  color: #c0c0c0;

  //media query 스타일이 1073px미만일때 적용되게 됩니다.
  @media screen and (max-width: 1023px) {
    font-size: 0.6rem;
    line-height: 1rem;
  }
`;
