import styled, { css } from "styled-components";

export const DetailPriceText = styled.div<{
  isPrice?: boolean;
  isFabric?: boolean;
}>`
  color: #000;
  font-family: "PretendardBold";
  font-size: ${(props) => (props.isPrice ? "1.5rem" : "1.25rem")};
  margin-bottom: 1rem;

  ${(props) =>
    props.isFabric &&
    css`
      display: inline-block;
      font-size: 1rem;
      margin-left: 0.5rem;
    `}
`;
