import styled from "styled-components";

export const DetailOptionStyledBox = styled.div<{ isSelected?: boolean }>`
  width: 11.9375rem;
  height: 17.90625rem;
  border: ${(props) =>
    props.isSelected ? "2px solid #D69457" : "1px solid #DBDBDB"};
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  cursor: pointer;

  //media query 스타일이 1073px미만일때 적용되게 됩니다.
  @media screen and (max-width: 1023px) {
    width: 100%;
    aspect-ratio: 1/3;
  }
`;
