import React from "react";
import Header from "../organisms/header/Header";
import { Outlet } from "react-router-dom";
import Footer from "../organisms/footer/Footer";
import Div from "../atoms/div";

const HeaderFooterOutlet = () => {
  return (
    <>
      <Div minHeight="88vh">
        <Header />
        <Outlet />
      </Div>
      <Footer />
    </>
  );
};

export default HeaderFooterOutlet;
