import styled from "styled-components";

export const NoticeContainer = styled.div`
  padding: 0 10vw;
  margin-bottom: 6rem;

  @media (max-width: 1023px) {
    padding: 0 20px;
  }
`;
