import axios from "axios";
import { OrderRequestType } from "~/types/order-type";
import { getCookie } from "~/utils/cookie";

const API_URL = process.env.REACT_APP_API_URL;

export const orderApi = async (order: OrderRequestType) => {
  const response = await axios.post(
    `${API_URL}/order`,
    {
      ...order,
      address: `${order.address} ${order.detail}`,
    },
    {
      headers: {
        Authorization: `Bearer ${getCookie("access_token")}`,
      },
    }
  );

  return response;
};
