import styled from "styled-components";

export const DetailContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 4rem 10%;

  //media query 스타일이 1073px미만일때 적용되게 됩니다.
  @media screen and (max-width: 1023px) {
    padding: 2rem 1rem;
  }
`;
