import React, { useState } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import { addQuantityApi } from "~/apis/cart/add-quantity-api";
import { minusQuantityApi } from "~/apis/cart/minus-quantity-api";
import { CartPlustMinusButton } from "~/components/atoms/cart/CartPlustMinusButton";
import Div from "~/components/atoms/div";
import { quantityTriggerState } from "~/store/quantity-trigger-state";

interface QuantityButtonProps {
  initialQuantity: number;
  id: number;
}

const QuantityButton = ({ initialQuantity, id }: QuantityButtonProps) => {
  const [quantity, setQuantity] = useState(initialQuantity);
  const [quantityTrigger, setQuantityTrigger] =
    useRecoilState(quantityTriggerState);

  function handleMinusButtonClick() {
    if (quantity <= 1) {
      alert("최소 수량은 1개입니다.");
      setQuantityTrigger(quantityTrigger + 1);
      return;
    }

    minusQuantityApi(id)
      .then((res) => {
        setQuantity(quantity - 1);
        setQuantityTrigger(quantityTrigger + 1);
      })
      .catch((err) => {
        alert(err.response.data.msg);
      });
  }

  function handlePlusButtonClick() {
    if (quantity >= 10) {
      alert("최대 수량은 10개입니다.");
      return;
    }

    addQuantityApi(id)
      .then((res) => {
        setQuantity(quantity + 1);
        setQuantityTrigger(quantityTrigger + 1);
      })
      .catch((err) => {
        alert(err.response.data.msg);
      });
  }

  return (
    <Div display="flex" justifyContent="center" alignItems="center">
      <CartPlustMinusButton onClick={handleMinusButtonClick}>
        -
      </CartPlustMinusButton>
      {quantity}
      <CartPlustMinusButton
        onClick={handlePlusButtonClick}
        style={{ position: "relative", right: "2px", bottom: "1px" }}
      >
        +
      </CartPlustMinusButton>
    </Div>
  );
};

export default QuantityButton;
