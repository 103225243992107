import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Div from "~/components/atoms/div";
import { HeaderMenuItem } from "~/components/atoms/header/HeaderMenuItem";
import { HeaderMenuItemContainer } from "~/components/atoms/header/HeaderMenuItemContainer";
import { HeaderSubMenuContainer } from "~/components/atoms/header/sub/HeaderSubMenuContainer";
import { HeaderSubMenuItem } from "~/components/atoms/header/sub/HeaderSubMenuItem";
import HeaderSubMenu from "./HeaderSubMenu";

const HeaderItem = () => {
  const navigate = useNavigate();
  const [isOnlineShow, setIsOnlineShow] = useState(false);
  const [isShirtsShow, setIsShirtsShow] = useState(false);
  const [isEtcShow, setIsEtcShow] = useState(false);

  const onlineMallDatas = [
    {
      title: "세밀한 상의",
      path: "/detail/top",
    },
    {
      title: "간편한 상의",
      path: "/simple/top",
    },
    {
      title: "세밀한 하의",
      path: "/detail/bottom",
    },
    {
      title: "간편한 하의",
      path: "/simple/bottom",
    },
    {
      title: "베스트",
      path: "/simple/vest",
    },
  ];

  const shirtsDatas = [
    {
      title: "세밀한 셔츠",
      path: "/detail/shirts",
    },
    {
      title: "간편한 셔츠",
      path: "/simple/shirts",
    },
  ];

  const etcDatas = [
    {
      title: "넥타이",
      path: "/etc/necktie",
    },
    {
      title: "멜빵",
      path: "/etc/strap",
    },
    {
      title: "카우핀",
      path: "/etc/pin",
    },
  ];

  function currentLocationCheck(path: string, path2?: string, path3?: string) {
    const currentLocation = window.location.pathname;
    if (
      currentLocation.includes(path) ||
      (path2 && currentLocation.includes(path2)) ||
      (path3 && currentLocation.includes(path3))
    ) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <HeaderMenuItemContainer>
      <Div display="flex">
        <HeaderMenuItem isMain={false} onClick={() => navigate("/landing")}>
          마므레
        </HeaderMenuItem>

        <HeaderMenuItem
          onMouseEnter={() => setIsOnlineShow(true)}
          onMouseLeave={() => setIsOnlineShow(false)}
          isOnlineMall
          isSelected={currentLocationCheck("top", "bottom", "vest")}
          isMain={false}
        >
          온라인몰
          <HeaderSubMenu isShow={isOnlineShow} datas={onlineMallDatas} />
        </HeaderMenuItem>

        <HeaderMenuItem
          onClick={() => navigate("/simple/coat")}
          isSelected={currentLocationCheck("coat")}
          isMain={false}
        >
          코트
        </HeaderMenuItem>

        <HeaderMenuItem
          onMouseEnter={() => setIsShirtsShow(true)}
          onMouseLeave={() => setIsShirtsShow(false)}
          isOnlineMall
          isSelected={currentLocationCheck("shirts")}
          isMain={false}
        >
          셔츠 <HeaderSubMenu isShow={isShirtsShow} datas={shirtsDatas} />
        </HeaderMenuItem>
        <HeaderMenuItem
          onMouseEnter={() => setIsEtcShow(true)}
          onMouseLeave={() => setIsEtcShow(false)}
          isSelected={currentLocationCheck("etc")}
          isOnlineMall
          isMain={false}
        >
          기타 액세서리 <HeaderSubMenu isShow={isEtcShow} datas={etcDatas} />
        </HeaderMenuItem>
        <HeaderMenuItem onClick={() => navigate("/notice")} isMain={false}>
          게시판
        </HeaderMenuItem>
      </Div>
    </HeaderMenuItemContainer>
  );
};

export default HeaderItem;
