import React from "react";
import { DetailOptionImage } from "~/components/atoms/detail/option/DetailOptionImage";
import { DetailPreviewStyledBox } from "~/components/atoms/detail/preview/DetailPreviewStyledBox";

interface DetailPreviewBoxProps {
  src: string;
}

const DetailPreviewBox = ({ src }: DetailPreviewBoxProps) => {
  return (
    <DetailPreviewStyledBox>
      {src !== undefined && <DetailOptionImage src={src} />}
    </DetailPreviewStyledBox>
  );
};

export default DetailPreviewBox;
