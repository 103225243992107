import styled from "styled-components";

export const HeaderSubMenuItem = styled.div<{ isLast?: boolean }>`
  width: 7rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "PretendardRegular";
  font-size: 0.9rem;
  box-sizing: border-box;

  border-bottom: ${(props) =>
    props.isLast ? "none" : "0.0625rem solid #e5e5e5"};

  &:hover {
    background-color: #f5f5f5;
  }

  /* left: x; */
`;
