import styled from "styled-components";
import SelectIcon from "~/assets/images/select-icon.png";

export const DetailStyledSelectBox = styled.select`
  width: 100%;
  height: 2.5rem;
  border: 1px solid #dbdbdb;
  border-radius: 0.5rem;
  background: #fff;
  box-sizing: border-box;

  font-family: "PretendardRegular";
  color: #767676;
  padding: 0 0.5rem;

  // 기본 화살표 아이콘 제거
  appearance: none;
  -webkit-appearance: none; /* 사파리, 크롬 하위버전용 */
  -moz-appearance: none; /* 사파리, 크롬 하위버전용 */

  // 새 화살표 아이콘 추가
  background-image: url(${SelectIcon});
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;

  ::-ms-expand {
    display: none; /* IE에서 선택화살표 제거 */
  }
`;
