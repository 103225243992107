import axios from "axios";
import { PaymentRequestType } from "~/types/payment-type";

const API_URL = process.env.REACT_APP_API_URL;

export const paymentConfirmApi = async (payment: PaymentRequestType) => {
  console.log(payment);
  const response = await axios.post(`${API_URL}/payment/confirm`, payment);

  return response;
};
