import React from "react";
import { TableContainer } from "~/components/atoms/table/TableContainer";
import { TableData } from "~/components/atoms/table/TableData";
import { TableDataRow } from "~/components/atoms/table/TableDataRow";
import { TableHeader } from "~/components/atoms/table/TableHeader";
import { TableHeaderRow } from "~/components/atoms/table/TableHeaderRow";
import DetailCheckBox from "../detail/DetailCheckBox";
import { useRecoilState } from "recoil";
import { checkboxSelectState } from "~/store/checkbox-select-state";
import { useNavigate } from "react-router-dom";
import { MyPageTableHeader } from "~/components/atoms/table/MyPageTableHeader";
import { MyPageTableData } from "~/components/atoms/table/MyPageTableData";

interface MyPageTableProps {
  datas: TableDataType[];
  checkboxDatas: string[];
  setCheckboxDatas: React.Dispatch<React.SetStateAction<string[]>>;
  isOrder?: boolean;
}

interface TableDataType {
  id: number;
  title: string;
  price: number;
  status: number;
}

const MyPageTable = ({
  datas,
  checkboxDatas,
  setCheckboxDatas,
  isOrder,
}: MyPageTableProps) => {
  const navigate = useNavigate();

  function navigateOrderDetailPage(id: number | string) {
    if (isOrder) {
      navigate(`/order/detail/${id}`);
    }
  }

  function getOrderStatus(status: number) {
    switch (status) {
      case 0:
        return "주문";
      case 1:
        return "환불요청";
      case 2:
        return "환불승인";
      case 3:
        return "환불거절";
    }
  }

  return (
    <TableContainer>
      <MyPageTableHeader>
        <TableHeaderRow></TableHeaderRow>
        <TableHeaderRow>번호</TableHeaderRow>
        <TableHeaderRow>상품 종류</TableHeaderRow>
        <TableHeaderRow>금액</TableHeaderRow>
        <TableHeaderRow>상태</TableHeaderRow>
      </MyPageTableHeader>
      {datas.map((data, index) => (
        <MyPageTableData
          key={data.id}
          onClick={() => navigateOrderDetailPage(data.id)}
        >
          <TableDataRow>
            <DetailCheckBox
              checkboxDatas={checkboxDatas}
              setCheckboxDatas={setCheckboxDatas}
              keyValue={data.id.toString()}
              isOrder
            />
          </TableDataRow>
          <TableDataRow>{index + 1}</TableDataRow>
          <TableDataRow>
            {data.title.length >= 7
              ? `${data.title.substring(0, 13)}...`
              : data.title}
          </TableDataRow>
          <TableDataRow>{data.price.toLocaleString()}</TableDataRow>
          <TableDataRow>{getOrderStatus(data.status)}</TableDataRow>
        </MyPageTableData>
      ))}
    </TableContainer>
  );
};

export default MyPageTable;
