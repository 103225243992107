import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const loginApi = async (email: string, password: string) => {
  console.log(API_URL);
  const response = await axios.post(`${API_URL}/user/login`, {
    email,
    password,
  });

  return response;
};
