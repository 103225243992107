import React, { useState } from "react";
import Div from "~/components/atoms/div";
import { MyPageInputLabel } from "~/components/atoms/mypage/MyPageInputLabel";
import { MyPagePWChangeButton } from "~/components/atoms/mypage/MyPagePWChangeButton";
import PasswordChangeModal from "~/components/templates/modal/PasswordChangeModal";

const MyPagePWField = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <Div display="flex" marginBottom="2rem" alignItems="center">
        <MyPageInputLabel>비밀번호</MyPageInputLabel>
        <MyPagePWChangeButton onClick={() => setIsModalOpen(true)}>
          비밀번호 변경
        </MyPagePWChangeButton>
      </Div>

      <PasswordChangeModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
};

export default MyPagePWField;
