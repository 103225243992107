import React from "react";
import MainFirstSection from "../molecules/main/MainFirstSection";
import MainSecondSection from "../molecules/main/MainSecondSection";
import MainThirdSection from "../molecules/main/MainThirdSection";
import MainInfoBox from "../molecules/main/infobox/MainInfoBox";
import MainIntroduction from "../organisms/main/introduction/MainIntroduction";
import { useMediaQuery } from "react-responsive";
import { MainSection } from "../organisms/main/MainSection";
import Section1Image from "~/assets/images/section1.png";
import Section2Image from "~/assets/images/section2.png";
import Section3Image from "~/assets/images/section3.png";
import Section4Image from "~/assets/images/section4.png";
import MobileSection1Image from "~/assets/images/mobile_section1.png";
import MobileSection2Image from "~/assets/images/mobile_section2.png";
import Div from "../atoms/div";
import { MobileMainNavButton } from "../atoms/main/MobileMenuButton";
import { MobileMenuButtonContainer } from "../atoms/main/MobileMenuButtonContainer";
import MobileMainNav from "../molecules/main/MobileMainNav";
import Main1Page from "~/assets/images/main/main1.jpeg";
import Main2Page from "~/assets/images/main/main2.jpeg";
import Main3Page from "~/assets/images/main/main3.jpeg";
import NewSaleImage from "~/assets/images/main/new_sale.jpg";

const MainPage = () => {
  const isTablet = useMediaQuery({
    query: "(max-width:1023px)",
  });

  return (
    <>
      {!isTablet && (
        <>
          <MainSection src={Section1Image} />
          <MainSection src={Section2Image} />
          <MainSection src={Section3Image} />
          <MainSection src={Section4Image} />
          <MainInfoBox />
          <MainIntroduction />
          <Div display="flex" width="100%" justifyContent="center">
            <img src={Main3Page} alt="main3" width="45%" />
            <img src={NewSaleImage} alt="main3" width="45%" />
          </Div>
        </>
      )}

      {isTablet && (
        <>
          <MobileMainNav />
          <MainSection src={MobileSection1Image} />

          <Div px="5vw">
            <MainSection src={MobileSection2Image} />
          </Div>

          <MainIntroduction />
          <MainInfoBox />

          <Div
            display="flex"
            flexDirection="column"
            width="100%"
            justifyContent="center"
          >
            <img src={Main3Page} alt="main3" width="100%" />
            <img src={NewSaleImage} alt="main3" width="100%" />
          </Div>
        </>
      )}
    </>
  );
};

export default MainPage;
