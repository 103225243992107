import styled, { css } from "styled-components";

export const HeaderContainer = styled.div<{ isMain?: boolean }>`
  width: 100%;
  height: 12vh;
  box-sizing: border-box;
  padding: 0 10%;
  display: grid;
  grid-template-columns: 2fr 6fr 3fr;
  align-items: center;
  background-color: #fdfdfd;

  ${(props) =>
    props.isMain &&
    css`
      background-color: black;
    `}

  //media query 스타일이 1073px미만일때 적용되게 됩니다.
  @media screen and (max-width: 1023px) {
    display: flex;
    justify-content: space-between;
  }
`;
