import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { paymentConfirmApi } from "~/apis/payment/payment-confirm-api";

import Div from "~/components/atoms/div";
import { OrderButton } from "~/components/atoms/order/OrderButton";
import { PaymentSuccessIcon } from "~/components/atoms/payment/success/PaymentSuccessIcon";
import { PaymentRequestType } from "~/types/payment-type";

const PaymentSuccessPage = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const navigate = useNavigate();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [payment, setPayment] = useState<PaymentRequestType>();

  // 1. custom hook으로 사용할 함수를 하나 생성한다.
  const preventClose = (e: any) => {
    e.preventDefault();
    if (isSuccess) {
      // 2. 결제가 완료되었을 경우, 아무런 동작을 하지 않는다.
      return navigate("/");
    }

    return navigate("/cart");
  };

  // 브라우저에 렌더링 시 한 번만 실행하는 코드
  useEffect(() => {
    (() => {
      if (isSuccess) {
      }
      window.addEventListener("beforeunload", preventClose);
      // 4. beforeunload 이벤트는 리소스가 사라지기 전 window 자체에서 발행한다.
      // 4-2. window의 이벤트를 감지하여 beforunload 이벤트 발생 시 preventClose 함수가 실행된다.
    })();

    return () => {
      window.removeEventListener("beforeunload", preventClose);
      // 5. 해당 이벤트 실행 후, beforeunload를 감지하는 것을 제거한다.
    };
  });

  useEffect(() => {
    paymentConfirmApi({
      paymentKey: params.get("paymentKey")!,
      orderId: params.get("orderId")!,
      amount: parseInt(params.get("amount")!, 10),
    })
      .then((res) => {
        alert("결제를 성공했습니다.");
        setIsSuccess(true);
      })
      .catch((err) => {
        alert(err.response.data.msg);
        navigate("/cart");
        console.log(err);
      });
  }, []);

  return (
    <Div
      display="flex"
      width="100%"
      height="70vh"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
    >
      <PaymentSuccessIcon />

      <Div fontFamily="PretendardBold" fontSize="1.3rem" mb="2rem">
        주문이 완료되었습니다.
      </Div>

      <OrderButton onClick={() => navigate("/")} isAll>
        홈으로 가기
      </OrderButton>
    </Div>
  );
};

export default PaymentSuccessPage;
