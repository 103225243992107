import styled from "styled-components";

export const PaymentContainer = styled.div<{ isProduct?: boolean }>`
  display: flex;
  gap: 1rem;
  padding: 0 10%;

  //media query 스타일이 1073px미만일때 적용되게 됩니다.
  @media screen and (max-width: 1023px) {
    flex-direction: column;
    padding: 0 1rem;
  }
`;
