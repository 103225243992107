import React from "react";
import Div from "~/components/atoms/div";
import { OrderPriceText } from "~/components/atoms/order/OrderPriceText";

interface OrderPriceProps {
  price: number;
}

const OrderPrice = ({ price }: OrderPriceProps) => {
  return (
    <Div
      height="4.5rem"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="flex-end"
      mb="4rem"
    >
      <OrderPriceText>총 주문 금액</OrderPriceText>
      <OrderPriceText isPrice>{price.toLocaleString()}원</OrderPriceText>
    </Div>
  );
};

export default OrderPrice;
