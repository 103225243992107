import React, { PropsWithChildren, useState } from "react";
import { ModalContainer } from "~/components/atoms/modal/ModalContainer";
import { ModalOverlay } from "~/components/atoms/modal/ModalOvelay";
import { AiOutlineClose } from "react-icons/ai";
import Div from "~/components/atoms/div";
import { IoIosArrowBack } from "react-icons/io";

interface ModalProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Modal = ({
  children,
  isModalOpen,
  setIsModalOpen,
}: PropsWithChildren<ModalProps>) => {
  return (
    <>
      {isModalOpen && (
        <ModalOverlay>
          <ModalContainer>
            <Div display="flex" justifyContent="space-between" mb="3rem">
              <IoIosArrowBack
                size="2.4rem"
                style={{ cursor: "pointer" }}
                onClick={() => setIsModalOpen(false)}
              />
              <AiOutlineClose
                size="2.4rem"
                style={{ cursor: "pointer" }}
                onClick={() => setIsModalOpen(false)}
              />
            </Div>

            {children}
          </ModalContainer>
        </ModalOverlay>
      )}
    </>
  );
};

export default Modal;
