import styled from "styled-components";

export const AccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10vh;

  //media query 스타일이 1073px미만일때 적용되게 됩니다.
  @media screen and (max-width: 1023px) {
    margin-bottom: 2rem;
    align-items: flex-start;
    padding: 1rem;
  }
`;
