import styled from "styled-components";

export const PaymentStyledInput = styled.input`
  height: 2.5rem;
  border-radius: 0.5rem;
  border: 1px solid #dbdbdb;
  padding: 0 1rem;
  background: #fff;

  font-family: "PretendardRegular";
  font-size: 1.1rem;
  color: #767676;
`;
