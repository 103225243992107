import styled from "styled-components";

export const MainFabricImage = styled.img`
  width: 100%;
  height: 35rem;

  //media query 스타일이 1073px미만일때 적용되게 됩니다.
  @media screen and (max-width: 1023px) {
    height: 15rem;
  }
`;
