import styled from "styled-components";

export const CartPlustMinusButton = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  font-family: "PretendardRegular";
  font-size: 1.3rem;
  color: color;
`;
