import styled from "styled-components";

export const MobileMainNavButton = styled.button`
  width: 100%;
  height: 2.5rem;
  border: none;
  font-family: "PretendardMedium";
  background: #f3f3f3;
  color: #6a6a6a;
  border-radius: 0.5rem;
  cursor: pointer;
`;
