import React from "react";
import { TableContainer } from "~/components/atoms/table/TableContainer";
import { TableData } from "~/components/atoms/table/TableData";
import { TableDataRow } from "~/components/atoms/table/TableDataRow";
import { TableHeader } from "~/components/atoms/table/TableHeader";
import { TableHeaderRow } from "~/components/atoms/table/TableHeaderRow";
import DetailCheckBox from "../detail/DetailCheckBox";
import { useRecoilState } from "recoil";
import { checkboxSelectState } from "~/store/checkbox-select-state";
import { useNavigate } from "react-router-dom";
import Div from "~/components/atoms/div";
import { CartPlustMinusButton } from "~/components/atoms/cart/CartPlustMinusButton";
import QuantityButton from "../cart/QuantityButton";

interface TableProps {
  datas: TableDataType[];
  checkboxDatas: string[];
  setCheckboxDatas: React.Dispatch<React.SetStateAction<string[]>>;
  isOrder?: boolean;
}

interface TableDataType {
  id: number;
  title: string;
  price: number;
  quantity: number;
}

const Table = ({
  datas,
  checkboxDatas,
  setCheckboxDatas,
  isOrder,
}: TableProps) => {
  const navigate = useNavigate();

  function navigateOrderDetailPage(id: number | string) {
    if (isOrder) {
      navigate(`/order/detail/${id}`);
    }
  }

  return (
    <TableContainer>
      <TableHeader>
        <TableHeaderRow></TableHeaderRow>
        <TableHeaderRow>번호</TableHeaderRow>
        <TableHeaderRow>상품 종류</TableHeaderRow>
        <TableHeaderRow>금액</TableHeaderRow>
        <TableHeaderRow>수량</TableHeaderRow>
      </TableHeader>
      {datas.map((data, index) => (
        <TableData
          key={data.id}
          onClick={() => navigateOrderDetailPage(data.id)}
        >
          <TableDataRow>
            <DetailCheckBox
              checkboxDatas={checkboxDatas}
              setCheckboxDatas={setCheckboxDatas}
              keyValue={data.id.toString()}
              isOrder
            />
          </TableDataRow>
          <TableDataRow>{index + 1}</TableDataRow>
          <TableDataRow>
            {data.title.length >= 7
              ? `${data.title.substring(0, 13)}...`
              : data.title}
          </TableDataRow>
          <TableDataRow>{data.price.toLocaleString()}</TableDataRow>
          <TableDataRow>
            <QuantityButton initialQuantity={data.quantity} id={data.id} />
          </TableDataRow>
        </TableData>
      ))}
    </TableContainer>
  );
};

export default Table;
