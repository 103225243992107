import styled, { css } from "styled-components";

export const HeaderMenuItem = styled.li<{
  isOnlineMall?: boolean;
  isSelected?: boolean;
  isMain?: boolean;
}>`
  font-family: ${(props) =>
    props.isSelected ? "PretendardBold" : "PretendardRegular"};
  margin-right: 2rem;
  font-size: 1rem;
  color: ${(props) => (props.isMain ? "#fff" : "#191919")};
  cursor: pointer;

  ${(props) =>
    props.isOnlineMall &&
    css`
      display: flex;
      flex-direction: column;
      position: relative;
    `}
`;
