import React, { PropsWithChildren, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useRecoilValue } from "recoil";
import { DetailGuideImage } from "~/components/atoms/detail/preview/DetailGuideImage";
import { DetailGuideSubText } from "~/components/atoms/detail/preview/DetailGuideSubText";
import { DetailPreviewBoxContainer } from "~/components/atoms/detail/preview/DetailPreviewBoxContainer";
import { DetailPreviewGridContainer } from "~/components/atoms/detail/preview/DetailPreviewGridContainer";
import { DetailSelectBoxTitle } from "~/components/atoms/detail/select/DetailSelectBoxTitle";
import Div from "~/components/atoms/div";
import DetailPreviewBox from "~/components/molecules/detail/DetailPreviewBox";
import { fabricSelectState } from "~/store/fabric-select-state";

interface IDetailPreviewProps {
  children: React.ReactNode;
  guideImage?: string;
  guideText?: string;
  guideImage2?: string;
}

const DetailPreview = ({
  children,
  guideImage,
  guideImage2,
  guideText,
}: IDetailPreviewProps) => {
  const fabricSelect = useRecoilValue(fabricSelectState);
  const isTablet = useMediaQuery({ maxWidth: 768 });

  return (
    <DetailPreviewBoxContainer>
      {guideImage && !isTablet && (
        <>
          <DetailSelectBoxTitle>치수측정 가이드</DetailSelectBoxTitle>
          <Div mb="1rem">
            {guideText &&
              guideText
                .split("\n")
                .map((text, index) => (
                  <DetailGuideSubText>{text}</DetailGuideSubText>
                ))}
          </Div>
          <DetailGuideImage src={guideImage} />
          {guideImage2 && <DetailGuideImage src={guideImage2} />}
        </>
      )}

      <DetailSelectBoxTitle>디자인</DetailSelectBoxTitle>
      <DetailPreviewGridContainer>{children}</DetailPreviewGridContainer>

      {fabricSelect.fabricColorImage && (
        <>
          <DetailSelectBoxTitle>원단</DetailSelectBoxTitle>
          <DetailPreviewGridContainer>
            {" "}
            <DetailPreviewBox src={fabricSelect.fabricImage} />
            <DetailPreviewBox src={fabricSelect.fabricColorImage} />
          </DetailPreviewGridContainer>
        </>
      )}
    </DetailPreviewBoxContainer>
  );
};

export default DetailPreview;
