import React from "react";
import Div from "~/components/atoms/div";
import { PaymentInputTitle } from "~/components/atoms/payment/input/PaymentInputTitle";
import { PaymentStyledDiv } from "~/components/atoms/payment/input/PaymentStyledDiv";
import { PaymentStyledInput } from "~/components/atoms/payment/input/PaymentStyledInput";

interface PaymentValueProps {
  title: string;
  value?: string;
}

const PaymentValue = ({ title, value }: PaymentValueProps) => {
  return (
    <Div display="flex" width="100%" flexDirection="column" mb="1rem">
      <PaymentInputTitle>{title}</PaymentInputTitle>
      <PaymentStyledDiv>{value}</PaymentStyledDiv>
    </Div>
  );
};

export default PaymentValue;
