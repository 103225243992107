import { atom } from "recoil";
import { FabricSelectType } from "~/types/fabric-type";

export const fabricSelectState = atom<FabricSelectType>({
  key: "fabricSelectState",
  default: {
    fabricId: 0,
    fabricColorId: 0,
    name: "",
    code: "",
    price: 0,
    fabricImage: "",
    fabricColorImage: "",
  },
});
