import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const minusQuantityApi = async (id: number) => {
  const response = await axios.get(
    `${API_URL}/userproduct/quantity/minus/${id}`
  );

  return response;
};
