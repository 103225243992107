import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getPostByIdApi } from "~/apis/post/get-post-by-id-api";
import Div from "~/components/atoms/div";
import { NoticeContainer } from "~/components/atoms/notice/NoticeContainer";
import { NoticeInfoText } from "~/components/atoms/notice/NoticeInfoText";
import { NoticeListBackButton } from "~/components/atoms/notice/NoticeListBackButton";
import { PostDetailType } from "~/types/post-type";
import "../../../styles/content-styles.css";

const NoticeDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState<PostDetailType>({
    id: 0,
    title: "",
    body: "",
  });

  useEffect(() => {
    getPostByIdApi(parseInt(id!, 10))
      .then((res) => {
        setPost(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <NoticeContainer>
      <NoticeInfoText>{post.title}</NoticeInfoText>

      <div
        className="ck-content"
        dangerouslySetInnerHTML={{ __html: post.body }}
      />

      <Div width="100%" height="1px" backgroundColor="#DBDBDB" my="2.5rem" />

      <Div display="flex" justifyContent="center">
        <NoticeListBackButton onClick={() => navigate("/notice")}>
          목록으로
        </NoticeListBackButton>
      </Div>
    </NoticeContainer>
  );
};

export default NoticeDetailPage;
