import styled from "styled-components";

export const FabricBox = styled.div`
  width: 100%;

  background: #fff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;

  //media query 스타일이 1073px미만일때 적용되게 됩니다.
  @media screen and (max-width: 1023px) {
    height: auto;
  }
`;
