import React from "react";
import { NoticeItemBox } from "~/components/atoms/notice/NoticeItemBox";
import { NoticeItemDate } from "~/components/atoms/notice/NoticeItemDate";
import { NoticeItemTitle } from "~/components/atoms/notice/NoticeItemTitle";

interface INoticeItemProps {
  title: string;
  date: string;
  onClick?: () => void;
}

const NoticeItem = ({ title, date, onClick }: INoticeItemProps) => {
  return (
    <NoticeItemBox onClick={onClick}>
      <NoticeItemTitle>{title}</NoticeItemTitle>
      <NoticeItemDate>{date}</NoticeItemDate>
    </NoticeItemBox>
  );
};

export default NoticeItem;
