import styled from "styled-components";

export const DetailContentsContainer = styled.div`
  display: grid;
  gap: 1.25rem;
  grid-template-columns: 1fr 1fr;

  //media query 스타일이 1073px미만일때 적용되게 됩니다.
  @media screen and (max-width: 1023px) {
    display: flex;
    flex-direction: column;
  }
`;
