import styled from "styled-components";

export const HeaderMenuAccountItem = styled.div<{
  isSelected?: boolean;
  isMain?: boolean;
}>`
  width: 100%;
  font-family: ${(props) =>
    props.isSelected ? "PretendardBold" : "PretendardRegular"};
  font-size: 0.8rem;
  color: ${(props) => (props.isMain ? "#fff" : "#191919")};
  text-align: center;
  cursor: pointer;
`;
