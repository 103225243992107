import axios from "axios";
import { getCookie } from "~/utils/cookie";

const API_URL = process.env.REACT_APP_API_URL;

export const deleteCartApi = async (cartIds: string[]) => {
  const response = await axios.delete(`${API_URL}/cart?ids=${cartIds}`);

  return response;
};
