import { atom } from "recoil";
import { OrderInfoType, OrderRequestType } from "~/types/order-type";

export const orderPrdocutIdState = atom<string[]>({
  key: "orderPrdocutIdState",
  default: [],
});

export const orderRequestState = atom<OrderRequestType>({
  key: "orderRequestState",
  default: {
    orderId: "",
    recipient: "",
    address: "",
    detail: "",
    zipCode: "",
    phone: "",
    customerRequest: "",
    deliveryRequest: "",
    totalPrice: 0,
    userProductIds: [],
  },
});

export const orderDetailState = atom<OrderInfoType>({
  key: "orderDetailState",
  default: {
    orderNumber: "",
    userEmail: "",
    userName: "",
    totalPrice: 0,
    products: [],
  },
});
