import styled from "styled-components";

export const LandingContainer = styled.div`
  padding: 5rem 10%;
  display: flex;

  margin: 0rem 0 5rem 0;

  //media query 스타일이 1073px미만일때 적용되게 됩니다.
  @media screen and (max-width: 1023px) {
    padding: 5rem 0;
  }
`;
