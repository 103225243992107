import styled from "styled-components";

export const ModalOverlay = styled.div`
  /* Fullscreen position */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent gray background */
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`;
