import axios from "axios";
import { getCookie } from "~/utils/cookie";

const API_URL = process.env.REACT_APP_API_URL;

export const changePasswordApi = async (password: string) => {
  const response = await axios.patch(
    `${API_URL}/user`,
    { password },
    {
      headers: {
        Authorization: `Bearer ${getCookie("access_token")}`,
      },
    }
  );

  return response;
};
