import React, { useEffect, useState } from "react";
import { DetailContainer } from "../../atoms/detail/DetailContainer";
import { DetailLeftSide } from "../../atoms/detail/DetailLeftSide";
import { DetailPageTitle } from "../../atoms/detail/DetailPageTitle";
import DetailSelectBox from "../../molecules/detail/DetailSelectBox";
import DetailInputBox from "../../molecules/detail/DetailInputBox";
import DetailOptions from "../../organisms/detail/DetailOptions";
import DetailOptionBox from "../../molecules/detail/DetailOptionBox";
import DetailFabricSelect from "../../molecules/detail/DetailFabricSelect";
import { DetailShopAddButton } from "../../atoms/detail/DetailShopAddButton";
import { DetailDivider } from "../../atoms/detail/DetailDivider";
import { DetailSubDescriptionText } from "../../atoms/detail/DetailSubDescriptionText";
import DetailPreview from "../../organisms/detail/DetailPreview";
import DetailPreviewBox from "../../molecules/detail/DetailPreviewBox";
import { DetailPriceText } from "../../atoms/detail/price/DetailPriceText";
import DetailPrice from "../../molecules/detail/DetailPrice";
import AdditionalInfoText from "../../atoms/detail/additional/AdditionalInfoText";
import DetailCheckBox from "../../molecules/detail/DetailCheckBox";
import { DetailContentsContainer } from "../../atoms/detail/DetailContentsContainer";
import CommonTitleText from "../../atoms/CommonTitleText";
import "~/assets/css/content-styles.css";
import { productOptions } from "~/utils/product-option";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fabricSelectState } from "~/store/fabric-select-state";
import { ProductType } from "~/types/product-type";
import { SelectedOptionType } from "~/types/selected-option-type";
import { getProductByTypeApi } from "~/apis/product/get-product-by-id-type";
import { getOptionByTypeApi } from "~/apis/product/get-option-by-type-api";
import { addCartApi } from "~/apis/cart/add-cart-api";
import { currentCategoryState } from "~/store/current-category-state";
import { getCookie } from "~/utils/cookie";
import { getFabricColorByIdApi } from "~/apis/fabric/get-fabric-color-by-id-api";
import "../../../styles/content-styles.css";

const NeckTieSimpleOrderPage = () => {
  const navigate = useNavigate();
  const fabricSelect = useRecoilValue(fabricSelectState);
  const setCurrentCategory = useSetRecoilState(currentCategoryState);
  const [totalPrice, setTotalPrice] = useState(0);
  const [product, setProduct] = useState<ProductType>({
    id: 0,
    title: "",
    detail: "",
    price: 0,
    type: "",
  });
  const [option, setOption] = useState<any>(null);
  const [selectedOption, setSelectedOption] = useState<SelectedOptionType[]>(
    []
  );
  const [additionalSelectedOption, setAdditionalSelectedOption] =
    useState<string>("");

  const [size, setSize] = useState({
    age: 0,
    height: 0,
    chestSize: 0,
    neckSize: "",
    waistSize: 0,
    sleeveLength: "",
    shirtsType: "",
  });

  /**
   * 초기 데이터를 받아오는 부분
   * 상품정보, 디테일, 아이템 가격, 타입등 정보를 들고온다.
   */
  useEffect(() => {
    getProductByTypeApi("ETC_NECKTIE")
      .then((res) => {
        setProduct(res.data.data);
        setTotalPrice(res.data.data.price);
        setCurrentCategory("ETC");
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  /**
   * 상품의 옵션정보들을 가져오는 부분
   */
  useEffect(() => {
    getOptionByTypeApi("ETC_NECKTIE")
      .then((res) => {
        setOption(res.data.data);
        setSelectedOption(Array(Object.keys(res.data.data).length).fill({}));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  /**
   * 옵션이 선택될 때마다, 최종 가격을 업데이트 하는 부분
   */
  useEffect(() => {
    let optionPrice = 0;
    selectedOption.forEach((item) => {
      if (item.price) {
        optionPrice += item.price;
      }
    });

    setTotalPrice(product.price + optionPrice);
  }, [selectedOption]);

  /**
   * 옵션을 선택했을 때 발생하는 이벤트 핸들러
   */
  function handleOptionSelect(index: number, optionId: SelectedOptionType) {
    // 현재 배열 상태 복사
    const newArray = [...selectedOption];

    // 특정 인덱스의 값을 변경
    if (newArray[index].id === optionId.id) {
      newArray[index] = {} as SelectedOptionType;
      console.log(newArray);
    } else {
      newArray[index] = optionId;
    }

    // 변경된 배열 상태를 세팅
    setSelectedOption(newArray);
  }

  function handleInputChange(
    e: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) {
    setSize({
      ...size,
      [key]: e.target.value,
    });
  }

  function handleChange(e: React.ChangeEvent<HTMLSelectElement>, key: string) {
    setSize({
      ...size,
      [key]: e.target.value,
    });
  }

  /**
   *
   */
  function handleAddCart() {
    if (!getCookie("access_token")) {
      alert("로그인이 필요한 서비스입니다.");
      return navigate("/login");
    }

    addCartApi(
      product.id,
      selectedOption.map((item) => item.id),
      size,
      null,
      []
    )
      .then((res) => {
        alert("장바구니에 추가되었습니다.");
        navigate("/cart");
      })
      .then((err) => {
        alert("다시 로그인해주세요.");
        navigate("/login");
      });
  }

  return (
    <DetailContainer>
      <DetailContentsContainer>
        <DetailLeftSide>
          <DetailPageTitle>넥타이 주문서</DetailPageTitle>

          {option != null &&
            Object.keys(option).map((value, index) => (
              <DetailOptions title={value} key={index}>
                {option[value].map((item: any) => (
                  <DetailOptionBox
                    key={item.id}
                    name={item.name}
                    value={item.id}
                    src={item.image}
                    onClick={() => {
                      handleOptionSelect(index, {
                        id: item.id,
                        image: item.image,
                        price: item.price,
                      });
                    }}
                    isSelected={selectedOption[index].id === item.id}
                  />
                ))}
              </DetailOptions>
            ))}

          <DetailPrice price={totalPrice} />

          <DetailShopAddButton onClick={handleAddCart}>
            장바구니 추가
          </DetailShopAddButton>
        </DetailLeftSide>

        <DetailPreview>
          {selectedOption.map((item, index) => (
            <DetailPreviewBox key={index} src={item.image} />
          ))}
        </DetailPreview>
      </DetailContentsContainer>

      <DetailDivider />

      <CommonTitleText title="상세설명" />

      <div
        className="ck-content"
        dangerouslySetInnerHTML={{ __html: product.detail }}
      />
    </DetailContainer>
  );
};

export default NeckTieSimpleOrderPage;
