import styled from "styled-components";

export const DetailPreviewStyledBox = styled.div`
  width: 100%;
  aspect-ratio: 4/6;
  border-radius: 1rem;
  border: 2px solid #dbdbdb;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  //media query 스타일이 1073px미만일때 적용되게 됩니다.
  @media screen and (max-width: 1023px) {
    width: 100%;
    aspect-ratio: 4/6;
  }
`;
