import styled from "styled-components";

export const AccountToSingupButton = styled.button`
  background-color: #fff;
  border-radius: 0.5rem;
  border: 2px solid #d69457;
  width: 100%;
  height: 3.5rem;

  font-family: "PretendardBold";
  font-size: 1.3rem;
  color: #000;
`;
