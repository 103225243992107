import styled from "styled-components";

export const MainInfoBoxContainer = styled.div`
  width: 100%;
  height: 11rem;
  background-color: #191919;
  border-radius: 1rem;
  padding: 0 2rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  align-items: center;

  //media query 스타일이 1073px미만일때 적용되게 됩니다.
  @media screen and (max-width: 1023px) {
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
  }
`;
