import styled from "styled-components";

export const DetailOptionsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  //media query 스타일이 1073px미만일때 적용되게 됩니다.
  @media screen and (max-width: 1023px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    /* row-gap: 0px; 추가적인 세로 간격 설정으로 겹침 방지 */
  }
`;
