import React, { useEffect, useState } from "react";
import { OrderContainer } from "../atoms/order/OrderContainer";
import { OrderTitle } from "../atoms/order/OrderTitle";
import Table from "../molecules/table/Table";
import Div from "../atoms/div";
import OrderPrice from "../molecules/order/OrderPrice";
import OrderButtonGroup from "../molecules/order/OrderButtonGroup";
import { OrderDeleteButton } from "../atoms/order/OrderDeleteButton";
import { getCartListApi } from "~/apis/cart/get-cart-list-api";
import { deleteCartApi } from "~/apis/cart/delete-cart-api";
import { useRecoilState } from "recoil";
import { orderPrdocutIdState } from "~/store/order-state";
import { useNavigate } from "react-router-dom";
import { quantityTriggerState } from "~/store/quantity-trigger-state";

interface CartType {
  id: number;
  title: string;
  price: number;
  quantity: number;
  options: string;
  userProductId: number;
}

const CartPage = () => {
  const navigate = useNavigate();
  const [cart, setCart] = useState<CartType[]>([]);
  const [selectedCarts, setSelectedCarts] = useState<string[]>([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [productIds, setProductIds] = useRecoilState(orderPrdocutIdState);
  const [quantityTrigger, setQuantityTrigger] =
    useRecoilState(quantityTriggerState);

  useEffect(() => {
    getCartListApi()
      .then((res) => {
        setCart(res.data.list);
        setTotalPrice(
          res.data.list.reduce((acc: any, cur: any) => acc + cur.price, 0)
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, [quantityTrigger]);

  function handleCartDeleteButtonClick() {
    deleteCartApi(selectedCarts).then((res) => {
      alert("삭제되었습니다.");
      window.location.reload();
    });
  }

  function handleSelectedOrderButtonClick() {
    if (selectedCarts.length === 0) {
      alert("상품을 선택해주세요.");
      return;
    }

    setProductIds(selectedCarts);
    navigate("/order");
  }

  function handleAllOrderButtonClick() {
    console.log(cart.map((item) => item.userProductId));
    setProductIds(cart.map((item) => item.userProductId.toString()));
    navigate("/order");
  }

  return (
    <OrderContainer>
      <Div display="flex" justifyContent="space-between">
        <OrderTitle>장바구니</OrderTitle>
        <OrderDeleteButton onClick={handleCartDeleteButtonClick}>
          항목 삭제
        </OrderDeleteButton>
      </Div>

      <Table
        datas={cart}
        checkboxDatas={selectedCarts}
        setCheckboxDatas={setSelectedCarts}
      />

      <Div height="5rem" />

      <Div display="flex" flexDirection="column" mb="2rem">
        <OrderPrice price={totalPrice} />
        <OrderButtonGroup
          selectedOnClickHandler={handleSelectedOrderButtonClick}
          allOnClickHandler={handleAllOrderButtonClick}
        />
      </Div>
    </OrderContainer>
  );
};

export default CartPage;
