import React from "react";
import Div from "~/components/atoms/div";
import { AccountInputTitle } from "~/components/atoms/account/AccountInputTitle";
import { AccountStyledInput } from "~/components/atoms/account/AccountStyledInput";

interface AccountInputProps {
  title: string;
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
}

const AccountInput = ({
  title,
  placeholder,
  value,
  onChange,
  type = "text",
}: AccountInputProps) => {
  return (
    <Div width="100%" display="flex" flexDirection="column" mb="1rem">
      <AccountInputTitle>{title}</AccountInputTitle>
      <AccountStyledInput
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        type={type}
      />
    </Div>
  );
};

export default AccountInput;
