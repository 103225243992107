import styled from "styled-components";

import React from "react";

interface CommonTitleTextProps {
  title: string;
}

const CommonTitleText = ({ title }: CommonTitleTextProps) => {
  return <CommonStyledTitleText>{title}</CommonStyledTitleText>;
};

export default CommonTitleText;

const CommonStyledTitleText = styled.div`
  font-family: "PretendardBold";
  font-size: 1.5rem;
  margin: 0 0 1rem 0;
`;
