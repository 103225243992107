import React, { useEffect, useState } from "react";
import { MyPageContainer } from "../atoms/mypage/MyPageContainer";
import { MyPageTitle } from "../atoms/mypage/MyPageTitle";
import MyPageIdField from "../molecules/mypage/MyPageIdField";
import MyPagePWField from "../molecules/mypage/MyPagePWField";
import Table from "../molecules/table/Table";
import { getUserDetailApi } from "~/apis/user/get-user-detail-api";
import MyPageTable from "../molecules/table/MyPageTable";

const MyPage = () => {
  const [userInfo, setUserInfo] = useState({
    email: "",
    orders: [],
  });
  const [orderHistory, setOrderHistory] = useState<string[]>([]);

  useEffect(() => {
    getUserDetailApi()
      .then((res) => {
        setUserInfo(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <MyPageContainer>
      <MyPageTitle>내 정보</MyPageTitle>

      <MyPageIdField id={userInfo.email} />
      <MyPagePWField />

      <MyPageTitle isSub>주문 내역</MyPageTitle>
      <MyPageTable
        datas={userInfo.orders}
        checkboxDatas={orderHistory}
        setCheckboxDatas={setOrderHistory}
        isOrder
      />
    </MyPageContainer>
  );
};

export default MyPage;
