import styled from "styled-components";

export const DetailStyledInput = styled.input`
  width: 100%;
  height: 2.5rem;
  border-radius: 0.5rem;
  border: 1px solid #dbdbdb;
  background: #fff;
  box-sizing: border-box;
  padding: 0 0.63rem;

  font-family: "PretendardRegular";
  color: #767676;
`;
