import React from "react";
import { KakaoButtonContainer } from "~/components/atoms/account/kakao/KakaoButtonContainer";
import { KakaoIcon } from "~/components/atoms/account/kakao/KakaoIcon";
import { NaverButtonContainer } from "~/components/atoms/account/naver/NaverButtonContainer";
import { NaverIcon } from "~/components/atoms/account/naver/NaverIcon";

interface NaverLoginButtonProps {
  onClick?: () => void;
}

const NaverLoginButton = ({ onClick }: NaverLoginButtonProps) => {
  return (
    <NaverButtonContainer onClick={onClick}>
      <NaverIcon />
      <div>네이버 계정으로 로그인</div>
    </NaverButtonContainer>
  );
};

export default NaverLoginButton;
