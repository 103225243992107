import axios from "axios";
import { getCookie } from "~/utils/cookie";

const API_URL = process.env.REACT_APP_API_URL;

export const getUserDetailApi = async () => {
  const response = await axios.get(`${API_URL}/user/me`, {
    headers: {
      Authorization: `Bearer ${getCookie("access_token")}`,
    },
  });

  return response;
};
