import React from "react";
import styled from "styled-components";

export const DetailFabricSelectTitle = styled.div`
  font-family: "PretendardBold";
  font-size: 1rem;
  color: #d69457;
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  cursor: pointer;
  text-decoration: underline;
`;
