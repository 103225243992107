import React, { useState } from "react";
import { DetailStyledSelectBox } from "../atoms/detail/select/DetailStyledSelectBox";
import Div from "../atoms/div";
import DetailSelectBox from "../molecules/detail/DetailSelectBox";
import { DetailOptionStyledBox } from "../atoms/detail/option/DetailOptionStyledBox";
import DetailOptionBox from "../molecules/detail/DetailOptionBox";
import DetailOptions from "../organisms/detail/DetailOptions";
import { DetailStyledInput } from "../atoms/detail/input/DetailStyledInput";
import DetailPreview from "../organisms/detail/DetailPreview";
import DetailPreviewBox from "../molecules/detail/DetailPreviewBox";
import DetailFabricSelect from "../molecules/detail/DetailFabricSelect";
import DetailInputBox from "../molecules/detail/DetailInputBox";
import { StyledCheckBox } from "../atoms/detail/checkbox/StyledCheckBox";
import Modal from "../molecules/modal/Modal";
import FabricItem from "../organisms/fabric/FabricItem";
import { FabricContainer } from "../atoms/fabric/FabricContainer";
import { FabricModalTitle } from "../atoms/fabric/modal/FabricModalTitle";
import { FabricModalSubTitle } from "../atoms/fabric/modal/FabricModalSubTitle";
import { cheapFabric, expensiveFabric } from "~/utils/fabric";
import FabricModal from "../templates/modal/FabricModal";
import HeaderSubMenu from "../molecules/header/HeaderSubMenu";
import Header from "../organisms/header/Header";
import AccountInput from "../molecules/account/AccountInput";
import { AccountSubmitButton } from "../atoms/account/AccountSubmitButton";
import { AccountDivider } from "../atoms/account/AccountDivider";
import KakaoLoginButton from "../molecules/login/KakaoLoginButton";
import { AccountHintText } from "../atoms/account/AccountHintText";
import { AccountToSingupButton } from "../atoms/account/AccountToSingupButton";
import { AccountLogo } from "../atoms/account/AccountLogo";
import { TableHeader } from "../atoms/table/TableHeader";
import { TableHeaderRow } from "../atoms/table/TableHeaderRow";
import { TableData } from "../atoms/table/TableData";
import { TableDataRow } from "../atoms/table/TableDataRow";
import Table from "../molecules/table/Table";
import { OrderButton } from "../atoms/order/OrderButton";
import { OrderTitle } from "../atoms/order/OrderTitle";
import { OrderDeleteButton } from "../atoms/order/OrderDeleteButton";
import { OrderPriceText } from "../atoms/order/OrderPriceText";
import OrderButtonGroup from "../molecules/order/OrderButtonGroup";
import OrderPrice from "../molecules/order/OrderPrice";
import { PaymentContainer } from "../atoms/payment/PaymentContainer";
import { PaymentItemContainer } from "../atoms/payment/PaymentItemContainer";
import { PaymentTitle } from "../atoms/payment/PaymentTitle";
import { PaymentProductContainer } from "../atoms/payment/PaymentProductContainer";
import { PaymentProductOptionTitle } from "../atoms/payment/PaymentProductOptionTitle";
import { PaymentProductTitle } from "../atoms/payment/PaymentProductTitle";
import PaymentInput from "../molecules/payment/PaymentInput";
import PaymentAddressInput from "../molecules/payment/PaymentAddressInput";
import { PaymentButton } from "../atoms/payment/PaymentButton";
import PaymentPrice from "../molecules/payment/PaymentPrice";
import FabricColorModal from "../templates/modal/FabricColorModal";

const ComponentTest = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  return (
    <>
      <Div px="5rem" py="5rem">
        {/* <FabricModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} /> */}
        <OrderTitle>장바구니</OrderTitle>
        {/* <Table
          datas={[
            { id: 1, name: "양복", price: 3000 },
            { id: 2, name: "양복2", price: 3000 },
          ]}
        /> */}
      </Div>
      {/* <OrderButtonGroup /> */}
      <OrderDeleteButton>옵션삭제</OrderDeleteButton>
    </>
  );
};

export default ComponentTest;
