import React from "react";
import { useMediaQuery } from "react-responsive";
import { FabricBox } from "~/components/atoms/fabric/FabricBox";
import { FabricDescription } from "~/components/atoms/fabric/FabricDescription";
import { FabricImage } from "~/components/atoms/fabric/FabricImage";
import { FabricTextContainer } from "~/components/atoms/fabric/FabricTextContainer";
import { FabricTitle } from "~/components/atoms/fabric/FabricTitle";
import FabricText from "~/components/molecules/fabric/FabricText";

interface FabricItemProps {
  title: string;
  description: string;
  src: string;
  onClick?: () => void;
}

const FabricItem = ({ title, description, src, onClick }: FabricItemProps) => {
  const isTablet = useMediaQuery({
    query: "(max-width:1023px)",
  });
  return (
    <FabricBox onClick={onClick}>
      <FabricImage src={src} />

      <FabricText title={title} description={description} />
    </FabricBox>
  );
};

export default FabricItem;
