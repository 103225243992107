import styled from "styled-components";
import Image from "~/assets/images/footer_logo.png";

export const FooterLogoImage = styled.img.attrs({
  src: Image,
})`
  width: 15rem;
  margin-right: 3rem;
  object-fit: contain;

  //media query 스타일이 1073px미만일때 적용되게 됩니다.
  @media screen and (max-width: 1023px) {
    width: 15rem;
    margin-bottom: 1rem;
  }
`;
