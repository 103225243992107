import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const getFabricByCategory = async (
  category: "TOP" | "BOTTOM" | "SHIRTS" | "COAT" | "VEST" | "ETC"
) => {
  const response = await axios.get(`${API_URL}/fabric/category/${category}`);

  return response;
};
