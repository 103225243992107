import React from "react";
import { MainFirstBackground } from "./components/atoms/main/first/MainFirstBackground";
import Header from "./components/organisms/header/Header";
import MainFirstSection from "./components/molecules/main/MainFirstSection";
import MainSecondSection from "./components/molecules/main/MainSecondSection";
import MainThirdSection from "./components/molecules/main/MainThirdSection";
import MainInfoBox from "./components/molecules/main/infobox/MainInfoBox";
import MainIntroduction from "./components/organisms/main/introduction/MainIntroduction";
import Footer from "./components/organisms/footer/Footer";
import { RecoilRoot } from "recoil";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import GlobalStyle from "~/styles/global_style";
import HeaderFooterOutlet from "./components/templates/HeaderFooterOutlet";
import MainPage from "./components/pages/MainPage";
import LandingPage from "./components/pages/LandingPage";
import ComponentTest from "./components/pages/ComponentTest";
import CoatSimpleOrderPage from "./components/pages/coat/CoatSimpleOrderPage";
import VestSimpleOrderPage from "./components/pages/vest/VestSimpleOrderPage";
import ShirtsDetailOrderPage from "./components/pages/shirts/ShirtsDetailOrderPage";
import ShirtsSimpleOrderPage from "./components/pages/shirts/ShirtsSimpleOrderPage";
import LoginPage from "./components/pages/account/LoginPage";
import SignupPage from "./components/pages/account/SignupPage";
import CartPage from "./components/pages/CartPage";
import OrderPage from "./components/pages/OrderPage";
import MyPage from "./components/pages/MyPage";
import { CookiesProvider } from "react-cookie";
import { AuthProvider } from "~/contexts/AuthContext";
import KakaoCallback from "~/utils/KakaoCallback";
import PaymentPage from "./components/pages/payment/PaymentPage";
import PaymentSuccessPage from "./components/pages/payment/PaymentSuccessPage";
import PaymentFailPage from "./components/pages/payment/PaymentFailPage";
import ScrollToTop from "~/utils/scroll-to-top";
import OrderDetailPage from "./components/pages/OrderDetailPage";
import NaverCallback from "~/utils/NaverCallback";
import SuitDetailOrderPage from "./components/pages/top/TopDetailOrderPage";
import SuitSimpleOrderPage from "./components/pages/top/TopSimpleOrderPage";
import TopDetailOrderPage from "./components/pages/top/TopDetailOrderPage";
import TopSimpleOrderPage from "./components/pages/top/TopSimpleOrderPage";
import BottomDetailOrderPage from "./components/pages/bottom/BottomDetailOrderPage";
import BottomSimpleOrderPage from "./components/pages/bottom/BottomSimpleOrderPage";
import NoticeDetailPage from "./components/pages/notice/NoticeDetailPage";
import NoticeListPage from "./components/pages/notice/NoticeListPage";
import NeckTieSimpleOrderPage from "./components/pages/etc/NecktieSimpleOrderPage";
import PinSimpleOrderPage from "./components/pages/etc/PinSimpleOrderPage";
import StrapSimpleOrderPage from "./components/pages/etc/StrapSimpleOrderPage";

function App() {
  return (
    <RecoilRoot>
      <CookiesProvider>
        <BrowserRouter>
          <ScrollToTop />
          <AuthProvider>
            <GlobalStyle />
            <Routes>
              <Route path="/" element={<HeaderFooterOutlet />}>
                <Route path="" element={<MainPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/kakao_login" element={<KakaoCallback />} />
                <Route path="/naver_login" element={<NaverCallback />} />
                <Route path="/signup" element={<SignupPage />} />
                <Route path="test" element={<ComponentTest />} />
                <Route path="landing" element={<LandingPage />} />
                <Route path="/detail/top" element={<TopDetailOrderPage />} />
                <Route path="/simple/top" element={<TopSimpleOrderPage />} />
                <Route
                  path="/detail/bottom"
                  element={<BottomDetailOrderPage />}
                />
                <Route
                  path="/simple/bottom"
                  element={<BottomSimpleOrderPage />}
                />
                <Route path="/simple/coat" element={<CoatSimpleOrderPage />} />
                <Route path="/simple/vest" element={<VestSimpleOrderPage />} />
                <Route
                  path="/detail/shirts"
                  element={<ShirtsDetailOrderPage />}
                />
                <Route
                  path="/simple/shirts"
                  element={<ShirtsSimpleOrderPage />}
                />
                <Route path="/cart" element={<CartPage />} />
                <Route path="/order" element={<OrderPage />} />
                <Route path="/order/detail/:id" element={<OrderDetailPage />} />
                <Route path="/mypage" element={<MyPage />} />
                <Route
                  path="/payment/success"
                  element={<PaymentSuccessPage />}
                />
                <Route path="/payment/fail" element={<PaymentFailPage />} />
                <Route path="/notice" element={<NoticeListPage />} />
                <Route path="/notice/:id" element={<NoticeDetailPage />} />
                <Route
                  path="/etc/necktie"
                  element={<NeckTieSimpleOrderPage />}
                />
                <Route path="/etc/pin" element={<PinSimpleOrderPage />} />
                <Route path="/etc/strap" element={<StrapSimpleOrderPage />} />
              </Route>
              <Route path="test" element={<ComponentTest />} />
            </Routes>
          </AuthProvider>
        </BrowserRouter>
      </CookiesProvider>
    </RecoilRoot>
  );
}

export default App;
