import styled from "styled-components";

export const AccountSubmitButton = styled.button`
  border: none;
  border-radius: 0.5rem;
  background: #d69457;
  width: 100%;
  height: 3.5rem;
  cursor: pointer;
  font-family: "PretendardBold";
  font-size: 1.35rem;
  color: #fff;
`;
