import React from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { HeaderContainer } from "~/components/atoms/header/HeaderContainer";
import { Logo } from "~/components/atoms/header/Logo";
import HeaderAccountItem from "~/components/molecules/header/HeaderAccountItem";
import HeaderItem from "~/components/molecules/header/HeaderItem";
import MobileHeader from "~/components/molecules/header/MobileHeader";
import LogoImage from "~/assets/images/logo.png";
import WhiteLogoImage from "~/assets/images/logo_white.png";

const Header = () => {
  const navigate = useNavigate();
  const isTablet = useMediaQuery({
    query: "(max-width:1023px)",
  });

  return (
    <HeaderContainer isMain={false}>
      <Logo onClick={() => navigate("/")} src={LogoImage} />
      {!isTablet && <HeaderItem />}
      {!isTablet && <HeaderAccountItem />}
      {isTablet && <MobileHeader />}
    </HeaderContainer>
  );
};

export default Header;
