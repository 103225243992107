import styled from "styled-components";

export const OrderDeleteButton = styled.button`
  width: 5.5rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  border: 2px solid #ea7676;
  background: #fff;
  cursor: pointer;

  /* BoxShadow */
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.13);

  /* Font */
  font-family: "PretendardBold";
  font-size: 0.85rem;

  &:hover {
    background-color: red;
    color: #fff;
  }
`;
