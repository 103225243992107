import axios from "axios";
import { getCookie } from "~/utils/cookie";

const API_URL = process.env.REACT_APP_API_URL;

export const getUserProductApi = async (product: string[]) => {
  const response = await axios.post(
    `${API_URL}/order/info`,
    {
      ids: product,
    },
    {
      headers: {
        Authorization: `Bearer ${getCookie("access_token")}`,
      },
    }
  );

  return response;
};
