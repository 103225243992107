import styled from "styled-components";

export const MainInfoBoxText = styled.div`
  font-family: "PretendardBold";
  font-size: 1.3rem;
  color: #fff;
  line-height: 2rem;

  //media query 스타일이 1073px미만일때 적용되게 됩니다.
  @media screen and (max-width: 1023px) {
    text-align: center;
    font-size: 1rem;
  }
`;
