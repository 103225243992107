import React from "react";
import Div from "~/components/atoms/div";
import { PaymentInputTitle } from "~/components/atoms/payment/input/PaymentInputTitle";
import { PaymentStyledInput } from "~/components/atoms/payment/input/PaymentStyledInput";

interface PaymentInputProps {
  title: string;
  value?: string;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
}

const PaymentInput = ({
  title,
  value,
  placeholder,
  onChange,
  type = "text",
}: PaymentInputProps) => {
  return (
    <Div display="flex" width="100%" flexDirection="column" mb="1rem">
      <PaymentInputTitle>{title}</PaymentInputTitle>
      <PaymentStyledInput
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        type={type}
      />
    </Div>
  );
};

export default PaymentInput;
