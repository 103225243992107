import styled from "styled-components";

export const PaymentStyledDiv = styled.div`
  height: 2.5rem;
  border-radius: 0.5rem;

  padding: 0 0.3rem;
  margin-top: 0.5rem;

  font-family: "PretendardRegular";
  font-size: 1.1rem;
  color: #767676;
`;
