import axios from "axios";
import { PaymentRequestType } from "~/types/payment-type";

const API_URL = process.env.REACT_APP_API_URL;

export const requestRefundApi = async (refund: any) => {
  const response = await axios.post(`${API_URL}/refund`, refund);

  return response;
};
