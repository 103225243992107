import React from "react";
import { useNavigate } from "react-router-dom";
import { MobileMainNavButton } from "~/components/atoms/main/MobileMenuButton";
import { MobileMenuButtonContainer } from "~/components/atoms/main/MobileMenuButtonContainer";

const MobileMainNav = () => {
  const navigate = useNavigate();

  return (
    <MobileMenuButtonContainer>
      <MobileMainNavButton onClick={() => navigate("/detail/top")}>
        세밀한 상의
      </MobileMainNavButton>
      <MobileMainNavButton onClick={() => navigate("/simple/top")}>
        간편한 상의
      </MobileMainNavButton>
      <MobileMainNavButton onClick={() => navigate("/detail/bottom")}>
        세밀한 하의
      </MobileMainNavButton>
      <MobileMainNavButton onClick={() => navigate("/simple/bottom")}>
        간편한 하의
      </MobileMainNavButton>
      <MobileMainNavButton onClick={() => navigate("/simple/vest")}>
        베스트
      </MobileMainNavButton>
      <MobileMainNavButton onClick={() => navigate("/simple/coat")}>
        코트
      </MobileMainNavButton>
      <MobileMainNavButton onClick={() => navigate("/detail/shirts")}>
        세밀한 셔츠
      </MobileMainNavButton>
      <MobileMainNavButton onClick={() => navigate("/simple/shirts")}>
        간편한 셔츠
      </MobileMainNavButton>
    </MobileMenuButtonContainer>
  );
};

export default MobileMainNav;
