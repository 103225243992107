import styled from "styled-components";

export const HeaderSubMenuContainer = styled.div<{ isShow?: boolean }>`
  display: ${({ isShow }) => (isShow ? "flex" : "none")};
  flex-direction: column;
  z-index: 10;
  color: #191919;
  position: absolute;
  top: 18px;
  border: 1px solid #e5e5e5;
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */
  transform: translateX(-50%);
  z-index: 100;
  background-color: #ffffff;

  left: 50%;
  /* right: 10px; */
`;
