import styled from "styled-components";

export const MyPagePWChangeButton = styled.button`
  cursor: pointer;
  color: white;
  font-family: "PretendardBold";
  font-size: 1rem;
  border: none;
  width: 8.75rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  background: #d69457;
`;
