import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginApi } from "~/apis/user/login-api";
import { AccountSubmitButton } from "~/components/atoms/account/AccountSubmitButton";
import Div from "~/components/atoms/div";
import AccountInput from "~/components/molecules/account/AccountInput";
import { useAuth } from "~/contexts/AuthContext";
import { setCookie } from "~/utils/cookie";

const LoginBox = () => {
  const navigate = useNavigate();
  const { login, isAuthenticated } = useAuth();
  const [loginInfo, setLoginInfo] = useState({
    email: "",
    password: "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    setLoginInfo({
      ...loginInfo,
      [key]: e.target.value,
    });
  };

  const handleSubmitButtonClick = () => {
    login(loginInfo);
  };

  return (
    <Div mb="1rem">
      <AccountInput
        title="아이디"
        placeholder="아이디를 입력하세요"
        value={loginInfo.email}
        onChange={(e) => handleChange(e, "email")}
      />
      <AccountInput
        title="비밀번호"
        placeholder="비밀번호를 입력하세요"
        value={loginInfo.password}
        onChange={(e) => handleChange(e, "password")}
        type="password"
      />

      <AccountSubmitButton onClick={handleSubmitButtonClick}>
        로그인하기
      </AccountSubmitButton>
    </Div>
  );
};

export default LoginBox;
