import React, { useEffect, useState } from "react";
import Div from "../atoms/div";
import { LandingContainer } from "../atoms/landing/LandingContainer";
import { LandingTitle } from "../atoms/landing/LandingTitle";
import LandingText from "../molecules/landing/LandingText";
import { LandingImage } from "../atoms/landing/LandingImage";
import { getIntroductionByIdApi } from "~/apis/introduction/get-introduction-by-id-api";

const LandingPage = () => {
  const [body, setBody] = useState<string>("");

  useEffect(() => {
    getIntroductionByIdApi(1)
      .then((res) => {
        setBody(res.data.data.body);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <LandingContainer>
      <div className="ck-content" dangerouslySetInnerHTML={{ __html: body }} />
    </LandingContainer>
  );
};

export default LandingPage;
