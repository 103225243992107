import React from "react";
import { DetailSelectBoxTitle } from "~/components/atoms/detail/select/DetailSelectBoxTitle";
import { DetailStyledSelectBox } from "~/components/atoms/detail/select/DetailStyledSelectBox";
import Div from "~/components/atoms/div";

interface DetailSelectBoxProps {
  title: string;
  options: { label: string; value: string | number }[];
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const DetailSelectBox = ({
  title,
  options,
  onChange,
}: DetailSelectBoxProps) => {
  return (
    <Div display="flex" flexDirection="column" mb="1rem">
      <DetailSelectBoxTitle>{title}</DetailSelectBoxTitle>
      <DetailStyledSelectBox onChange={onChange}>
        <option value="" selected>
          옵션을 선택하세요
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </DetailStyledSelectBox>
    </Div>
  );
};

export default DetailSelectBox;
