import React from "react";
import { FooterContainer } from "~/components/atoms/footer/FooterContainer";
import { FooterLogoImage } from "~/components/atoms/footer/FooterLogoImage";
import { FooterText } from "~/components/atoms/footer/FooterText";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterLogoImage />
      <FooterText>
        상호 : 마므레(MAMRE) | 대표 : 유두현
        <br />
        대표번호: 02-484-5819 | 모바일 전화: 010-6732-5819
        <br />
        사업자등록번호 : 441-14-01610 <br />
        주소 : 서울특별시 강동구 천호대로 1089 강동역 파밀리에 테라자상가 B17{" "}
        <br />
        통신판매업 등록번호 : 2022-서울강동-0403 <br />
        Copyright © 2023 마므레 All rights reserved
        <br />
        이용약관 | 개인정보처리방침 <br />
      </FooterText>
    </FooterContainer>
  );
};

export default Footer;
