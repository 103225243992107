import React from "react";
import { AccountLogo } from "../../atoms/account/AccountLogo";
import { AccountContainer } from "../../atoms/account/AccountContainer";
import AccountInput from "../../molecules/account/AccountInput";
import { AccountSubmitButton } from "../../atoms/account/AccountSubmitButton";
import Div from "../../atoms/div";
import { AccountDivider } from "../../atoms/account/AccountDivider";
import { AccountHintText } from "../../atoms/account/AccountHintText";
import KakaoLoginButton from "../../molecules/login/KakaoLoginButton";
import { AccountToSingupButton } from "../../atoms/account/AccountToSingupButton";
import LoginBox from "../../organisms/account/LoginBox";
import SocialLoginBox from "../../organisms/account/SocialLoginBox";
import { AccountTitle } from "../../atoms/account/AccountTitle";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const navigate = useNavigate();
  const isTablet = useMediaQuery({
    query: "(max-width:1023px)",
  });

  return (
    <AccountContainer>
      {!isTablet && <AccountLogo />}

      <AccountTitle>로그인하기</AccountTitle>
      <Div width={isTablet ? "100%" : "50vw"}>
        <LoginBox />

        <AccountDivider />

        <SocialLoginBox />

        <AccountHintText>아직 회원이 아니신가요?</AccountHintText>
        <AccountToSingupButton onClick={() => navigate("/signup")}>
          회원가입 하기
        </AccountToSingupButton>
      </Div>
    </AccountContainer>
  );
};

export default LoginPage;
