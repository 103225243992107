import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { signupApi } from "~/apis/user/signup-api";
import { AccountContainer } from "~/components/atoms/account/AccountContainer";
import { AccountSubmitButton } from "~/components/atoms/account/AccountSubmitButton";
import { AccountTitle } from "~/components/atoms/account/AccountTitle";
import Div from "~/components/atoms/div";
import AccountInput from "~/components/molecules/account/AccountInput";
import DetailCheckBox from "~/components/molecules/detail/DetailCheckBox";

const SignupPage = () => {
  const isTablet = useMediaQuery({
    query: "(max-width:1023px)",
  });

  const navigate = useNavigate();
  const [signupUser, setSignupUser] = useState({
    email: "",
    password: "",
    passwordConfirm: "",
    name: "",
    phone: "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    setSignupUser({
      ...signupUser,
      [key]: e.target.value,
    });
  };

  const handleSubnitButtonClick = () => {
    if (signupUser.password !== signupUser.passwordConfirm) {
      alert("비밀번호가 일치하지 않습니다.");
      return false;
    }

    signupApi(signupUser)
      .then((res) => {
        alert("회원가입이 완료되었습니다.");
        navigate("/login");
      })
      .catch((err) => {
        alert(err.response.data.msg);
      });
  };

  return (
    <Div my="3rem">
      <AccountContainer>
        <AccountTitle>회원가입</AccountTitle>

        <Div width={isTablet ? "100%" : "50vw"}>
          <AccountInput
            title="아이디"
            placeholder="아이디를 입력해주세요"
            type="email"
            value={signupUser.email}
            onChange={(e) => handleChange(e, "email")}
          />
          <AccountInput
            title="비밀번호"
            placeholder="비밀번호를 입력해주세요"
            type="password"
            value={signupUser.password}
            onChange={(e) => handleChange(e, "password")}
          />
          <AccountInput
            title="비밀번호 재입력"
            placeholder="비밀번호를 다시 입력해주세요"
            type="password"
            value={signupUser.passwordConfirm}
            onChange={(e) => handleChange(e, "passwordConfirm")}
          />
          <AccountInput
            title="이름"
            placeholder="이름을 입력하세요"
            value={signupUser.name}
            onChange={(e) => handleChange(e, "name")}
          />
          <AccountInput
            title="전화번호"
            placeholder="전화번호를 입력하세요"
            value={signupUser.phone}
            onChange={(e) => handleChange(e, "phone")}
          />

          {/* <DetailCheckBox title="약관동의" keyValue="signup" /> */}

          <Div height="1rem" />

          <AccountSubmitButton onClick={handleSubnitButtonClick}>
            회원가입하기
          </AccountSubmitButton>
        </Div>
      </AccountContainer>
    </Div>
  );
};

export default SignupPage;
