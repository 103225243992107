import styled from "styled-components";

export const NoticeListBackButton = styled.button`
  border: 1px solid #191919;
  background-color: #fff;
  width: 12.5rem;
  height: 3.5rem;
  font-family: "PretendardBold";
  font-size: 1.2rem;
  cursor: pointer;
`;
