import styled from "styled-components";

export const MainFabricColorContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 3rem;

  //media query 스타일이 767px미만일때 적용되게 됩니다.
  //media query 스타일이 1073px미만일때 적용되게 됩니다.
  @media screen and (max-width: 1023px) {
    flex-direction: column;
  }
`;
