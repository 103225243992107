import styled from "styled-components";

export const AccountHintText = styled.div`
  color: #000;
  font-family: "PretendardBold";
  font-size: 1rem;
  margin: 0 0 0.7rem 10px;

  //media query 스타일이 1073px미만일때 적용되게 됩니다.
  @media screen and (max-width: 1023px) {
    font-size: 1.1rem;
  }
`;
