import React, { useEffect, useState } from "react";
import { DetailContainer } from "../../atoms/detail/DetailContainer";
import { DetailLeftSide } from "../../atoms/detail/DetailLeftSide";
import { DetailPageTitle } from "../../atoms/detail/DetailPageTitle";
import DetailSelectBox from "../../molecules/detail/DetailSelectBox";
import DetailInputBox from "../../molecules/detail/DetailInputBox";
import DetailOptions from "../../organisms/detail/DetailOptions";
import DetailOptionBox from "../../molecules/detail/DetailOptionBox";
import DetailFabricSelect from "../../molecules/detail/DetailFabricSelect";
import { DetailShopAddButton } from "../../atoms/detail/DetailShopAddButton";
import { DetailDivider } from "../../atoms/detail/DetailDivider";
import { DetailSubDescriptionText } from "../../atoms/detail/DetailSubDescriptionText";
import DetailPreview from "../../organisms/detail/DetailPreview";
import DetailPreviewBox from "../../molecules/detail/DetailPreviewBox";
import { DetailPriceText } from "../../atoms/detail/price/DetailPriceText";
import DetailPrice from "../../molecules/detail/DetailPrice";
import AdditionalInfoText from "../../atoms/detail/additional/AdditionalInfoText";
import DetailCheckBox from "../../molecules/detail/DetailCheckBox";
import { DetailContentsContainer } from "../../atoms/detail/DetailContentsContainer";
import CommonTitleText from "../../atoms/CommonTitleText";
import "~/assets/css/content-styles.css";
import { getOptionByTypeApi } from "~/apis/product/get-option-by-type-api";
import { productOptions } from "~/utils/product-option";
import { ProductType } from "~/types/product-type";
import { getProductByTypeApi } from "~/apis/product/get-product-by-id-type";
import { addCartApi } from "~/apis/cart/add-cart-api";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { fabricSelectState } from "~/store/fabric-select-state";
import { AccountStyledInput } from "~/components/atoms/account/AccountStyledInput";
import Div from "~/components/atoms/div";
import { MainFabricColorRadioInput } from "~/components/atoms/fabric_color/MainFabricColorRadioInput";
import { DetailSelectBoxTitle } from "~/components/atoms/detail/select/DetailSelectBoxTitle";
import { DetailPreviewBoxContainer } from "~/components/atoms/detail/preview/DetailPreviewBoxContainer";
import { getFabricColorByIdApi } from "~/apis/fabric/get-fabric-color-by-id-api";
import { DetailOptionImage } from "~/components/atoms/detail/option/DetailOptionImage";
import { FabricColorImage } from "~/components/atoms/detail/fabric/FabricColorImage";
import TopGuideImage from "~/assets/images/top_info.png";
import BottomGuideImage from "~/assets/images/bottom_info.png";
import { currentCategoryState } from "~/store/current-category-state";
import { getCookie } from "~/utils/cookie";
import PanthLengthGuideImage from "~/assets/images/pant_length_guide.jpg";
import WaistSizeGuideImage from "~/assets/images/waist_guide_1.png";
import InitialGuideImage from "~/assets/images/pants_initial_guide.jpg";
import "../../../styles/content-styles.css";
import { useMediaQuery } from "react-responsive";
import { DetailGuideImage } from "~/components/atoms/detail/preview/DetailGuideImage";

interface SelectedOptionType {
  id: number;
  image: string;
  price: number;
}

const BottomDetailOrderPage = () => {
  const navigate = useNavigate();
  const isTablet = useMediaQuery({ maxWidth: 768 });
  const fabricSelect = useRecoilValue(fabricSelectState);
  const [totalPrice, setTotalPrice] = useState(0);
  const setCurrentCategory = useSetRecoilState(currentCategoryState);
  const [product, setProduct] = useState<ProductType>({
    id: 0,
    title: "",
    detail: "",
    price: 0,
    type: "",
  });
  const [option, setOption] = useState<any>(null);
  const [selectedOption, setSelectedOption] = useState<SelectedOptionType[]>(
    []
  );
  const [pantsLengthOption, setPantsLengthOption] = useState<number>(0);
  const [waistSizeOption, setWaistSizeOption] = useState<number>(0);
  const [initialOption, setInitialOption] = useState<string>("");

  const [size, setSize] = useState({
    age: 0,
    height: 0,
    chestSize: 0,
    waistSize: 0,
    sleeveSize: 0,
    sleeveLength: 0,
  });

  /**
   * 초기 데이터를 받아오는 부분
   * 상품정보, 디테일, 아이템 가격, 타입등 정보를 들고온다.
   */
  useEffect(() => {
    getProductByTypeApi("BOTTOM")
      .then((res) => {
        setProduct(res.data.data);
        setTotalPrice(res.data.data.price);
        setCurrentCategory("BOTTOM");
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  /**
   * 상품의 옵션정보들을 가져오는 부분
   */
  useEffect(() => {
    getOptionByTypeApi("BOTTOM")
      .then((res) => {
        setOption(res.data.data);
        setSelectedOption(Array(Object.keys(res.data.data).length).fill({}));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  /**
   * 옵션이 선택될 때마다, 최종 가격을 업데이트 하는 부분
   */
  useEffect(() => {
    let optionPrice = 0;
    selectedOption.forEach((item) => {
      if (item.price) {
        optionPrice += item.price;
      }
    });

    setTotalPrice(product.price + optionPrice);
  }, [selectedOption]);

  /**
   * 옵션을 선택했을 때 발생하는 이벤트 핸들러
   */
  function handleOptionSelect(index: number, optionId: SelectedOptionType) {
    // 현재 배열 상태 복사
    const newArray = [...selectedOption];

    // 특정 인덱스의 값을 변경
    if (newArray[index].id === optionId.id) {
      newArray[index] = {} as SelectedOptionType;
      console.log(newArray);
    } else {
      newArray[index] = optionId;
    }

    // 변경된 배열 상태를 세팅
    setSelectedOption(newArray);
  }

  function handleInputChange(
    e: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) {
    setSize({
      ...size,
      [key]: e.target.value,
    });
  }

  function handleChange(e: React.ChangeEvent<HTMLSelectElement>, key: string) {
    setSize({
      ...size,
      [key]: e.target.value,
    });
  }

  /**
   *
   */
  function handleAddCart() {
    if (fabricSelect.fabricId === 0) {
      return alert("원단을 선택해주세요.");
    }

    if (!getCookie("access_token")) {
      alert("로그인이 필요한 서비스입니다.");
      return navigate("/login");
    }

    addCartApi(
      product.id,
      selectedOption.map((item) => item.id),
      size,
      fabricSelect,
      [
        {
          title: "바지기장(선택)",
          value: pantsLengthOption.toString(),
          price: 0,
        },
        {
          title: "허리둘레 절반(선택)",
          value: waistSizeOption.toString(),
          price: 0,
        },
      ]
    )
      .then((res) => {
        alert("장바구니에 추가되었습니다.");
        navigate("/cart");
      })
      .catch((err) => {
        alert("다시 로그인해주세요.");
        navigate("/login");
      });
  }

  return (
    <DetailContainer>
      <DetailContentsContainer>
        <DetailLeftSide>
          <DetailPageTitle>세밀한 하의 주문서</DetailPageTitle>

          {isTablet && <DetailGuideImage src={BottomGuideImage} />}

          <DetailSelectBox
            title="나이"
            options={productOptions.ages}
            onChange={(e) => handleChange(e, "age")}
          />

          <DetailSelectBox
            title="키 (cm)"
            options={productOptions.heights}
            onChange={(e) => handleChange(e, "height")}
          />

          <DetailSelectBox
            title="허리둘레"
            options={productOptions.inchWaists}
            onChange={(e) => handleChange(e, "waistSize")}
          />

          <DetailSubDescriptionText>
            *아래는 허리 둘레가 잘 맞는 기성복 정장 바지 입었을 때를 기준으로
            선택해주세요*
          </DetailSubDescriptionText>

          <DetailSelectBox
            title="허벅지"
            options={productOptions.thighs}
            onChange={(e) => handleChange(e, "thighSize")}
          />
          <DetailSelectBox
            title="엉덩이"
            options={productOptions.hips}
            onChange={(e) => handleChange(e, "hipSize")}
          />

          {option != null &&
            Object.keys(option).map((value, index) => (
              <DetailOptions title={value} key={index}>
                {option[value].map((item: any) => (
                  <DetailOptionBox
                    key={item.id}
                    name={item.name}
                    value={item.id}
                    src={item.image}
                    onClick={() => {
                      handleOptionSelect(index, {
                        id: item.id,
                        image: item.image,
                        price: item.price,
                      });
                    }}
                    isSelected={selectedOption[index].id === item.id}
                  />
                ))}
              </DetailOptions>
            ))}

          <DetailFabricSelect />

          <AdditionalInfoText />

          <DetailSubDescriptionText>
            *자세한 선택사항은 하단 설명을 참조해주세요.(원하시는 고객만 선택
            부탁드립니다.)*
          </DetailSubDescriptionText>

          <DetailSelectBox
            title="바지기장(선택)"
            options={productOptions.pantLengthInch}
            onChange={(e) => setPantsLengthOption(Number(e.target.value))}
          />

          <Div width={isTablet ? "100%" : "26rem"} height="13rem">
            <DetailOptionImage src={PanthLengthGuideImage} />
          </Div>

          <DetailSelectBox
            title="허리둘레 절반(선택)"
            options={productOptions.waistAdditionalInch}
            onChange={(e) => setWaistSizeOption(Number(e.target.value))}
          />

          <Div width={isTablet ? "100%" : "26rem"} height="13rem">
            <DetailOptionImage src={WaistSizeGuideImage} />
          </Div>

          <DetailPrice price={fabricSelect.price} />

          <DetailShopAddButton onClick={handleAddCart}>
            장바구니 추가
          </DetailShopAddButton>
        </DetailLeftSide>

        <DetailPreview guideImage={BottomGuideImage}>
          {selectedOption.map((item, index) => (
            <DetailPreviewBox key={index} src={item.image} />
          ))}
        </DetailPreview>
      </DetailContentsContainer>

      <DetailDivider />

      <CommonTitleText title="상세설명" />

      <div
        className="ck-content"
        dangerouslySetInnerHTML={{ __html: product.detail }}
      />
    </DetailContainer>
  );
};

export default BottomDetailOrderPage;
