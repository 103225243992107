import styled from "styled-components";

export const DetailShopAddButton = styled.button`
  width: 12.3125rem;
  height: 3.5rem;
  border: none;
  background-color: #d69457;
  border-radius: 0.5rem;
  font-family: "PretendardBold";
  font-size: 1.1rem;
  color: #fff;
  cursor: pointer;
`;
