import React, { PropsWithChildren } from "react";
import { DetailOptionsContainer } from "~/components/atoms/detail/DetailOptionsContainer";
import { DetailOptionHintText } from "~/components/atoms/detail/option/DetailOptionHintText";
import { DetailSelectBoxTitle } from "~/components/atoms/detail/select/DetailSelectBoxTitle";
import Div from "~/components/atoms/div";

interface DetailOptionsProps {
  title: string;
  hintText?: string;
}

const DetailOptions = ({
  title,
  hintText,
  children,
}: PropsWithChildren<DetailOptionsProps>) => {
  return (
    <Div display="flex" flexDirection="column">
      <DetailSelectBoxTitle>{title}</DetailSelectBoxTitle>
      {hintText && <DetailOptionHintText>{hintText}</DetailOptionHintText>}
      <DetailOptionsContainer>{children}</DetailOptionsContainer>
    </Div>
  );
};

export default DetailOptions;
