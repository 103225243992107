import React from "react";
import Div from "~/components/atoms/div";
import { OrderButton } from "~/components/atoms/order/OrderButton";

interface OrderButtonClick {
  selectedOnClickHandler: () => void;
  allOnClickHandler: () => void;
}

const OrderButtonGroup = ({
  selectedOnClickHandler,
  allOnClickHandler,
}: OrderButtonClick) => {
  return (
    <Div display="flex" justifyContent="flex-end">
      <OrderButton onClick={selectedOnClickHandler}>선택상품 주문</OrderButton>
      <OrderButton onClick={allOnClickHandler} isAll>
        전체상품 주문
      </OrderButton>
    </Div>
  );
};

export default OrderButtonGroup;
