import styled from "styled-components";

export const AccountStyledInput = styled.input`
  width: 100%;
  height: 2.5rem;
  border-radius: 0.5rem;
  border: 1px solid #dbdbdb;

  box-sizing: border-box;
  font-family: "PretendardRegular";
  padding: 0 1rem;
`;
