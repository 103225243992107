import styled from "styled-components";
// import Image from "~/assets/images/naver.png";

export const NaverButtonContainer = styled.div`
  width: 100%;
  height: 3.5rem;
  background: #03c75a;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "PretendardRegular";
  font-size: 1.25rem;
  cursor: pointer;
`;
