import React from "react";
import Div from "~/components/atoms/div";
import { MyPageIDText } from "~/components/atoms/mypage/MyPageIDText";
import { MyPageInputLabel } from "~/components/atoms/mypage/MyPageInputLabel";

interface MyPageIdFieldProps {
  id: string;
}

const MyPageIdField = ({ id }: MyPageIdFieldProps) => {
  return (
    <Div display="flex" mb="1rem">
      <MyPageInputLabel>아이디</MyPageInputLabel>
      <MyPageIDText>{id}</MyPageIDText>
    </Div>
  );
};

export default MyPageIdField;
