import React from "react";
import { useNavigate } from "react-router-dom";
import { HeaderSubMenuContainer } from "~/components/atoms/header/sub/HeaderSubMenuContainer";
import { HeaderSubMenuItem } from "~/components/atoms/header/sub/HeaderSubMenuItem";

interface SubMenuType {
  title: string;
  path: string;
}

interface HeaderSubMenuProps {
  isShow: boolean;
  datas: SubMenuType[];
}

const HeaderSubMenu = ({ isShow, datas }: HeaderSubMenuProps) => {
  const navigate = useNavigate();

  return (
    <HeaderSubMenuContainer isShow={isShow}>
      {datas.map((data, index) => (
        <HeaderSubMenuItem key={index} onClick={() => navigate(data.path)}>
          {data.title}
        </HeaderSubMenuItem>
      ))}
    </HeaderSubMenuContainer>
  );
};

export default HeaderSubMenu;
