import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const getProductByTypeApi = async (
  type:
    | "TOP"
    | "SIMPLE_TOP"
    | "BOTTOM"
    | "SIMPLE_BOTTOM"
    | "COAT"
    | "VEST"
    | "SHIRTS"
    | "SIMPLE_SHIRTS"
    | "ETC_NECKTIE"
    | "ETC_PIN"
    | "ETC_STRAP"
) => {
  const response = await axios.get(`${API_URL}/product/${type}`);

  return response;
};
