import React from "react";
import Div from "~/components/atoms/div";
import { PaymentAddressStyledInput } from "~/components/atoms/payment/input/PaymentAddressStyledInput";
import { PaymentInputTitle } from "~/components/atoms/payment/input/PaymentInputTitle";
import { BsSearch } from "react-icons/bs";
import { PaymentStyledInput } from "~/components/atoms/payment/input/PaymentStyledInput";
import { useDaumPostcodePopup } from "react-daum-postcode";
import { useRecoilState } from "recoil";
import { orderRequestState } from "~/store/order-state";

const PaymentAddressInput = () => {
  const [orderRequest, setOrderRequest] = useRecoilState(orderRequestState);
  const open = useDaumPostcodePopup(
    "https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js"
  );

  const handleComplete = (data: any) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    console.log(fullAddress); // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'
    setOrderRequest({
      ...orderRequest,
      address: fullAddress,
      zipCode: data.zonecode,
    });
  };

  const handleClick = () => {
    open({ onComplete: handleComplete });
  };

  return (
    <Div display="flex" flexDirection="column" width="100%" mb="1rem">
      <PaymentInputTitle>배송주소</PaymentInputTitle>
      <PaymentAddressStyledInput onClick={handleClick}>
        {orderRequest.address === "" ? "우편번호 찾기" : orderRequest.address}
        <BsSearch />
      </PaymentAddressStyledInput>
      <PaymentStyledInput
        placeholder="상세주소"
        value={orderRequest.detail}
        onChange={(e) =>
          setOrderRequest({
            ...orderRequest,
            detail: e.target.value,
          })
        }
      />
    </Div>
  );
};

export default PaymentAddressInput;
