import React from "react";
import Div from "~/components/atoms/div";
import { InfoBoxHeadSet } from "~/components/atoms/main/infobox/InfoBoxHeadSet";
import { MainInfoBoxContainer } from "~/components/atoms/main/infobox/MainInfoBoxContainer";
import { MainInfoBoxText } from "~/components/atoms/main/infobox/MainInfoBoxText";

const MainInfoBox = () => {
  return (
    <Div
      width="100%"
      height="20rem"
      px="8vw"
      display="flex"
      alignContent="center"
    >
      <MainInfoBoxContainer>
        <MainInfoBoxText>
          상품 주문에 어려움을 느끼고 계신가요?
          <br />
          부담없이 전화 주세요! <br />
        </MainInfoBoxText>
        <InfoBoxHeadSet />
      </MainInfoBoxContainer>
    </Div>
  );
};

export default MainInfoBox;
