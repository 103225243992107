import React from "react";
import Div from "~/components/atoms/div";
import { PaymentPriceText } from "~/components/atoms/payment/price/PaymentPriceText";

interface PaymentPriceProps {
  isPayment?: boolean;
  price: number;
}

const PaymentPrice = ({ isPayment, price }: PaymentPriceProps) => {
  return (
    <Div
      height="4.5rem"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      mb="4rem"
    >
      <PaymentPriceText>
        {isPayment ? "총 결제 금액" : "총 주문 금액"}
      </PaymentPriceText>
      <PaymentPriceText isPrice>{price.toLocaleString()}원</PaymentPriceText>
    </Div>
  );
};

export default PaymentPrice;
