import CheckBoxIcon from "~/assets/images/checkbox.png";
import styled from "styled-components";

export const StyledCheckBox = styled.input`
  display: none;

  & + label {
    cursor: pointer;
  }

  & + label > span {
    font-family: "PretendardRegular";
    font-size: 1rem;
    color: #000000;
    vertical-align: middle;
    padding-left: 7px;
  }

  & + label > #hintText {
    font-family: "PretendardRegular";
    font-size: 0.8rem;
    color: #767676;
    margin-left: 0.5rem;
  }

  & + label::before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 1px solid #767676;
    border-radius: 4px;
    vertical-align: middle;
  }

  &:checked + label::before {
    content: "";
    width: 17px;
    height: 17px;
    border: none;
    background-image: url(${CheckBoxIcon});
    background-repeat: no-repeat;
    background-position: 50%;
  }
`;
