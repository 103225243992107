import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getPostListApi } from "~/apis/post/get-post-list-api";
import Div from "~/components/atoms/div";
import { NoticeContainer } from "~/components/atoms/notice/NoticeContainer";
import { NoticeInfoText } from "~/components/atoms/notice/NoticeInfoText";
import { NoticeItemContainer } from "~/components/atoms/notice/NoticeItemContainer";
import NoticeItem from "~/components/molecules/notice/info/NoticeItem";
import { PostListType } from "~/types/post-type";

const NoticeListPage = () => {
  const [posts, setPosts] = useState<PostListType[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    getPostListApi()
      .then((res) => {
        setPosts(res.data.list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <NoticeContainer>
        <NoticeInfoText>게시판</NoticeInfoText>
        <NoticeItemContainer>
          {posts.length === 0 && (
            <Div my="1rem">해당하는 게시글이 없습니다.</Div>
          )}

          {posts.map((post, index) => (
            <NoticeItem
              title={post.title}
              date={dayjs(post.createdDate).format("YYYY년 MM월 DD일")}
              key={index}
              onClick={() => navigate("/notice/" + post.id)}
            />
          ))}
        </NoticeItemContainer>
      </NoticeContainer>
    </>
  );
};

export default NoticeListPage;
