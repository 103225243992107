import styled from "styled-components";
import {
  space,
  color,
  typography,
  layout,
  border,
  flexbox,
  background,
  position,
  shadow,
  grid,
  SpaceProps,
  ColorProps,
  TypographyProps,
  LayoutProps,
  BorderProps,
  FlexboxProps,
  BackgroundProps,
  PositionProps,
  ShadowProps,
  GridProps,
} from "styled-system";

type DivProps = SpaceProps &
  ColorProps &
  TypographyProps &
  LayoutProps &
  BorderProps &
  FlexboxProps &
  BackgroundProps &
  PositionProps &
  ShadowProps &
  GridProps;

const Div = styled.div<DivProps>`
  box-sizing: border-box;
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${flexbox}
  ${background}
  ${border}
  ${position}
  ${shadow}
  ${grid}
`;
export default Div;
