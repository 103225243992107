import styled from "styled-components";

export const KakaoButtonContainer = styled.div`
  width: 100%;
  height: 3.5rem;
  background: #fee500;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;

  font-family: "PretendardRegular";
  font-size: 1.25rem;
  position: relative;
`;
