import styled from "styled-components";

export const MobileMenubarWrapper = styled.div`
  position: fixed;
  top: 70px;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 150px;
  height: 25rem;
  text-align: left;
  padding: 24px;
  background-color: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow-y: scroll;
`;
