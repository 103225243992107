import styled from "styled-components";

import React from "react";

const AdditionalInfoText = () => {
  return <AdditionalInfoStyledText>추가 선택사항</AdditionalInfoStyledText>;
};

export default AdditionalInfoText;

const AdditionalInfoStyledText = styled.div`
  font-family: "PretendardBold";
  font-size: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;
