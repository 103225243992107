import React, { useEffect } from "react";
import { useAuth } from "~/contexts/AuthContext";

const NaverCallback = () => {
  const code = new URL(window.location.href).searchParams.get("code");
  const { naverLogin } = useAuth();

  useEffect(() => {
    naverLogin(code!);
  }, []);

  return <></>;
};

export default NaverCallback;
