import styled from "styled-components";
import { DetailOptionContainer } from "~/components/atoms/detail/option/DetailOptionContainer";
import { DetailOptionImage } from "~/components/atoms/detail/option/DetailOptionImage";
import { DetailOptionStyledBox } from "~/components/atoms/detail/option/DetailOptionStyledBox";

interface DetailOptionBoxProps {
  name?: string;
  value?: number;
  src: string;
  isSelected?: boolean;
  onClick?: () => void;
}

const DetailOptionBox = ({
  src,
  isSelected,
  name,
  onClick,
  value,
}: DetailOptionBoxProps) => {
  return (
    <DetailOptionContainer>
      <DetailOptionStyledBox isSelected={isSelected} onClick={onClick}>
        <DetailOptionImage src={src} alt={name} />
      </DetailOptionStyledBox>
      <OptionNameText>({name})</OptionNameText>
    </DetailOptionContainer>
  );
};

export default DetailOptionBox;

const OptionNameText = styled.div`
  font-family: "Pretendard";
  font-size: 0.9rem;
`;
