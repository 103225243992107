import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useRecoilState } from "recoil";
import { getFabricByIdApi } from "~/apis/fabric/get-fabric-by-id-api";
import Div from "~/components/atoms/div";
import { FabricContainer } from "~/components/atoms/fabric/FabricContainer";
import { FabricModalSubTitle } from "~/components/atoms/fabric/modal/FabricModalSubTitle";
import { FabricModalTitle } from "~/components/atoms/fabric/modal/FabricModalTitle";
import { MainFabricColorContainer } from "~/components/atoms/fabric_color/MainFabricColorContainer";
import { MainFabricColorMade } from "~/components/atoms/fabric_color/MainFabricColorMade";
import { MainFabricColorRadioInput } from "~/components/atoms/fabric_color/MainFabricColorRadioInput";
import { MainFabricDescription } from "~/components/atoms/fabric_color/MainFabricDescription";
import { MainFabricImage } from "~/components/atoms/fabric_color/MainFabricImage";
import { MainFabricRadioContainer } from "~/components/atoms/fabric_color/MainFabricRadioContainer";
import MainFabricRadioInput from "~/components/molecules/fabric_color/MainFabricRadioInput";
import Modal from "~/components/molecules/modal/Modal";
import FabricItem from "~/components/organisms/fabric/FabricItem";
import { fabricModalInfoState } from "~/store/fabric-color-state";
import { fabricSelectState } from "~/store/fabric-select-state";
import { FabricColorDetailType } from "~/types/fabric-type";
import { cheapFabric, expensiveFabric, shirtsFabric } from "~/utils/fabric";

interface FabricColorModalProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  parentIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const FabricColorModal = ({
  isModalOpen,
  setIsModalOpen,
  parentIsModalOpen,
}: FabricColorModalProps) => {
  const [fabricSelect, setFabricSelect] = useRecoilState(fabricSelectState);
  const isTablet = useMediaQuery({ query: "(max-width: 1023px)" });
  const [fabricModalInfo, setFabricModalInfo] =
    useRecoilState(fabricModalInfoState);

  const [fabricDetail, setFabricDetail] = useState<FabricColorDetailType>({
    image: "",
    name: "",
    description: "",
    price: 0,
    colors: [],
  });

  useEffect(() => {
    if (fabricModalInfo.id !== 0) {
      getFabricByIdApi(fabricModalInfo.id)
        .then((res) => {
          setFabricDetail(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [fabricModalInfo]);

  function handleFabricColorClick(id: number, code: string, image: string) {
    console.log(code);
    setFabricSelect({
      ...fabricSelect,
      fabricColorId: id,
      code: code,
      price: fabricDetail.price,
      fabricColorImage: image,
    });

    setIsModalOpen(false);
    parentIsModalOpen(false);
  }

  return (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
      <FabricModalTitle>{fabricDetail.name}</FabricModalTitle>
      <FabricModalSubTitle>
        원하시는 원단과 번호를 선택해주세요
      </FabricModalSubTitle>

      <MainFabricColorContainer>
        <MainFabricImage src={fabricDetail.image} />
        <MainFabricDescription>
          {fabricDetail.name}
          <Div fontFamily="PretendardRegular" my="1rem">
            {fabricDetail.description}
          </Div>
        </MainFabricDescription>
      </MainFabricColorContainer>

      {/* 셔츠가 원단을 보여줌 */}

      <FabricContainer>
        {fabricDetail.colors.map((fabric, index) => (
          <FabricItem
            key={index}
            src={fabric.image}
            title={fabric.code}
            description={fabric.description}
            onClick={() =>
              handleFabricColorClick(fabric.id, fabric.code, fabric.image)
            }
          />
        ))}
      </FabricContainer>
    </Modal>
  );
};

export default FabricColorModal;
