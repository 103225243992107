import axios from "axios";
import { SignupUserType } from "~/types/signup-user-type";

const API_URL = process.env.REACT_APP_API_URL;

export const signupApi = async (user: SignupUserType) => {
  console.log(API_URL);
  const response = await axios.post(`${API_URL}/user/signup`, user);

  return response;
};
