import React from "react";
import Div from "~/components/atoms/div";
import { MainIntroductionBackground } from "~/components/atoms/main/introduction/MainIntroductionBackground";
import { MainMiJosaImage } from "~/components/atoms/main/introduction/MainMiJosaImage";
import MainIntroductionText from "~/components/molecules/main/introduction/MainIntroductionText";

const MainIntroduction = () => {
  return (
    <MainIntroductionBackground>
      <MainIntroductionText />
      <MainMiJosaImage />
    </MainIntroductionBackground>
  );
};

export default MainIntroduction;
