import styled from "styled-components";

export const FooterContainer = styled.div`
  height: 12vh;
  border-top: 1px solid #dbdbdb;
  margin-top: 2rem;
  padding: 4rem 12vw;
  display: flex;
  align-items: center;
  background-color: #333333;
  /* position: relative;
  transform: translateY(-100%); */

  //media query 스타일이 1073px미만일때 적용되게 됩니다.
  @media screen and (max-width: 1023px) {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem 10vw;
  }
`;
