import React, { useState } from "react";
import { changePasswordApi } from "~/apis/user/change-password-api";
import Div from "~/components/atoms/div";
import { PaymentButton } from "~/components/atoms/payment/PaymentButton";
import Modal from "~/components/molecules/modal/Modal";
import SmallModal from "~/components/molecules/modal/SmallModal";
import PaymentInput from "~/components/molecules/payment/PaymentInput";

interface PasswordChangeModalProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const PasswordChangeModal = ({
  isModalOpen,
  setIsModalOpen,
}: PasswordChangeModalProps) => {
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");

  function handlePasswordChange() {
    if (password !== passwordCheck) {
      alert("비밀번호가 일치하지 않습니다.");
      return;
    }

    changePasswordApi(password)
      .then((res) => {
        alert("성공적으로 비밀번호가 변경되었습니다.");
        setIsModalOpen(false);
      })
      .catch((err) => {
        alert("비밀번호 변경에 실패하였습니다.");
      });
  }

  return (
    <SmallModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
      <Div fontSize="1.8rem" fontFamily="PretendardBold" marginBottom="2rem">
        비밀번호 변경하기
      </Div>
      <PaymentInput
        title=""
        placeholder="새 비밀번호를 입력하세요"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        type="password"
      />

      <PaymentInput
        title=""
        placeholder="비밀번호를 다시 입력해주세요"
        value={passwordCheck}
        onChange={(e) => setPasswordCheck(e.target.value)}
        type="password"
      />

      <PaymentButton onClick={handlePasswordChange}>변경하기</PaymentButton>
    </SmallModal>
  );
};

export default PasswordChangeModal;
