import styled from "styled-components";
import HeadsetImage from "~/assets/images/headset.png";

export const InfoBoxHeadSet = styled.img.attrs({ src: HeadsetImage })`
  width: 5rem;
  object-fit: contain;

  //media query 스타일이 1073px미만일때 적용되게 됩니다.
  @media screen and (max-width: 1023px) {
    width: 4rem;
  }
`;
