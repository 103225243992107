import React from "react";
import { useRecoilState } from "recoil";
import { StyledCheckBox } from "~/components/atoms/detail/checkbox/StyledCheckBox";
import Div from "~/components/atoms/div";
import { checkboxSelectState } from "~/store/checkbox-select-state";

interface DetailCheckBoxProps {
  title?: string;
  hintText?: string;
  isOrder?: boolean;
  keyValue: string;
  checkboxDatas: string[];
  setCheckboxDatas: React.Dispatch<React.SetStateAction<string[]>>;
}

const DetailCheckBox = ({
  title,
  hintText,
  isOrder,
  keyValue,
  checkboxDatas,
  setCheckboxDatas,
}: DetailCheckBoxProps) => {
  function handleCheckBoxClick(value: string) {
    console.log(value);
    const index = checkboxDatas.indexOf(value);
    const copyArray = [...checkboxDatas];

    if (index === -1) {
      copyArray.push(value);
    } else {
      copyArray.splice(index, 1);
    }

    setCheckboxDatas([...copyArray]);
    console.log(copyArray);
  }

  return (
    <Div mb={isOrder ? 0 : "1rem"}>
      <StyledCheckBox
        type="checkbox"
        id={`check_btn_${keyValue}`}
        name={`check_btn_${keyValue}`}
        value={keyValue}
        onChange={(e) => handleCheckBoxClick(e.target.value)}
      />
      <label htmlFor={`check_btn_${keyValue}`}>
        {title && <span>{title}</span>}
        {hintText && <span id="hintText">{hintText}</span>}
      </label>
    </Div>
  );
};

export default DetailCheckBox;
