import axios from "axios";
import { AdditionOptionType } from "~/types/additional-option-type";
import { FabricSelectType } from "~/types/fabric-type";
import { getCookie } from "~/utils/cookie";

const API_URL = process.env.REACT_APP_API_URL;

export const addCartApi = async (
  productId: number,
  optionIds: number[],
  size: any,
  fabric: FabricSelectType | null,
  addtionalOptions: AdditionOptionType[]
) => {
  if (!fabric) {
    const response = await axios.post(
      `${API_URL}/cart`,
      {
        productId,
        optionIds,
        size,
        fabricId: 9999,
        fabricColorId: 9999,
        additional: addtionalOptions,
      },
      {
        headers: {
          Authorization: `Bearer ${getCookie("access_token")}`,
        },
      }
    );
    return response;
  }

  const response = await axios.post(
    `${API_URL}/cart`,
    {
      productId,
      optionIds,
      size,
      fabricId: fabric.fabricId,
      fabricColorId: fabric.fabricColorId,
      additional: addtionalOptions,
    },
    {
      headers: {
        Authorization: `Bearer ${getCookie("access_token")}`,
      },
    }
  );

  return response;
};
