import React from "react";
import Div from "~/components/atoms/div";
import { PaymentProductContainer } from "~/components/atoms/payment/PaymentProductContainer";
import { PaymentProductOptionTitle } from "~/components/atoms/payment/PaymentProductOptionTitle";
import { PaymentProductTitle } from "~/components/atoms/payment/PaymentProductTitle";
import { AdditionOptionType } from "~/types/additional-option-type";

interface PaymentProductProps {
  title: string;
  fabricTitle: string;
  fabricCode: string;
  quantity: number;
  options: any[];
  additionalOptions: AdditionOptionType[];
}

const PaymentProduct = ({
  title,
  fabricTitle,
  fabricCode,
  additionalOptions,
  options,
  quantity,
}: PaymentProductProps) => {
  console.log(options);
  return (
    <PaymentProductContainer>
      <PaymentProductTitle>
        {title}, {quantity}개
      </PaymentProductTitle>
      <Div display="flex">
        <PaymentProductOptionTitle>
          원단: {fabricTitle} / 컬러코드: {fabricCode}
        </PaymentProductOptionTitle>
      </Div>

      <Div display="flex" flexDirection="column">
        <PaymentProductOptionTitle>
          {options.map((option) => (
            <div key={option}>
              {option.category}: {option.name}
            </div>
          ))}
        </PaymentProductOptionTitle>

        <PaymentProductOptionTitle>
          {additionalOptions.map((option, index) => (
            <div key={index}>
              {option.title}: {option.value}
            </div>
          ))}
        </PaymentProductOptionTitle>
      </Div>
    </PaymentProductContainer>
  );
};

export default PaymentProduct;
