import styled from "styled-components";

export const NoticeInfoText = styled.div`
  width: 100%;
  padding: 2.5rem 0 1.5rem 0;
  font-family: "PretendardBold";
  font-size: 1.7rem;

  border-bottom: 1px solid #dbdbdb;
`;
