import React, { useEffect, useRef, useState } from "react";
import { PaymentContainer } from "../atoms/payment/PaymentContainer";
import { PaymentItemContainer } from "../atoms/payment/PaymentItemContainer";
import { PaymentTitle } from "../atoms/payment/PaymentTitle";
import Div from "../atoms/div";
import { PaymentProductContainer } from "../atoms/payment/PaymentProductContainer";
import { PaymentProductTitle } from "../atoms/payment/PaymentProductTitle";
import { PaymentProductOptionTitle } from "../atoms/payment/PaymentProductOptionTitle";
import PaymentInput from "../molecules/payment/PaymentInput";
import PaymentAddressInput from "../molecules/payment/PaymentAddressInput";
import { PaymentButton } from "../atoms/payment/PaymentButton";
import PaymentPrice from "../molecules/payment/PaymentPrice";
import PaymentProduct from "../molecules/payment/PaymentProduct";
import { getUserProductApi } from "~/apis/cart/get-userproduct-api";
import { useRecoilState } from "recoil";
import {
  orderDetailState,
  orderPrdocutIdState,
  orderRequestState,
} from "~/store/order-state";
import { orderApi } from "~/apis/order/order-api";
import Payment from "~/components/pages/payment/PaymentPage";
import { useNavigate, useParams } from "react-router-dom";
import { validatePriceApi } from "~/apis/order/validate-order-price-api";
import PaymentValue from "../molecules/payment/PaymentValue";
import { OrderDetailType } from "~/types/order-type";
import { getOrderByIdApi } from "~/apis/order/get-order-by-id-api";
import { OrderButton } from "../atoms/order/OrderButton";
import { requestRefundApi } from "~/apis/refund/request-refund-api";
import { OrderPaymentContainer } from "../atoms/order/OrderPaymentContainer";

const OrderDetailPage = () => {
  const { id } = useParams();

  const [order, setOrder] = useState<OrderDetailType>({
    orderNumber: "",
    email: "",
    name: "",
    phone: "",
    address: "",
    totalPrice: 0,
    status: 0,
    zipCode: "",
    customerRequest: "",
    deliveryRequest: "",
    products: [],
  });

  const [refund, setRefund] = useState({
    orderId: "",
    reason: "",
  });

  useEffect(() => {
    setRefund({
      ...refund,
      orderId: id!,
    });

    getOrderByIdApi(id!)
      .then((res) => {
        setOrder(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function handleRefundSubmitButtonClick() {
    const isConfirm = window.confirm("정말로 환불을 진행하시겠습니까?");

    if (!isConfirm) {
      return;
    }

    requestRefundApi(refund)
      .then((res) => {
        alert("환불 요청이 완료되었습니다. 영업일 기준 3 ~ 5일 소요됩니다.");
      })
      .catch((err) => {
        alert("환불에 실패하였습니다.");
      });
  }

  return (
    <PaymentContainer>
      <PaymentItemContainer isProduct isDetail>
        <PaymentTitle>상품</PaymentTitle>

        <OrderPaymentContainer>
          {order.products.map((product) => (
            <PaymentProduct
              title={product.title}
              options={product.options}
              quantity={product.quantity}
              additionalOptions={product.additionalOptions}
              fabricTitle={product.fabricName}
              fabricCode={product.fabricCode}
            />
          ))}
        </OrderPaymentContainer>
      </PaymentItemContainer>

      <PaymentItemContainer isDetail>
        <PaymentTitle>주문정보</PaymentTitle>
        <PaymentValue title="수령인" value={order.name} />

        <PaymentValue title="전화번호" value={order.phone} />
        <PaymentValue title="주소지" value={order.address} />

        <PaymentValue title="배송요청사항" value={order.deliveryRequest} />

        <PaymentValue title="기타추가사항" value={order.customerRequest} />
        <Div height="1rem" />

        <PaymentPrice price={order.totalPrice} isPayment />

        {order.status == 0 && (
          <>
            <PaymentInput
              title="환불사유"
              value={refund.reason}
              onChange={(e) => setRefund({ ...refund, reason: e.target.value })}
              placeholder="환불 사유를 입력하세요."
            />
            <OrderButton onClick={handleRefundSubmitButtonClick} isAll>
              환불하기
            </OrderButton>
          </>
        )}
      </PaymentItemContainer>
    </PaymentContainer>
  );
};

export default OrderDetailPage;
