import React from "react";
import { useNavigate } from "react-router-dom";
import Div from "~/components/atoms/div";
import { OrderButton } from "~/components/atoms/order/OrderButton";
import { PaymentErrorIcon } from "~/components/atoms/payment/success/PaymentErrorIcon";
import { PaymentSuccessIcon } from "~/components/atoms/payment/success/PaymentSuccessIcon";

const PaymentFailPage = () => {
  const navigate = useNavigate();
  return (
    <Div
      display="flex"
      width="100%"
      height="70vh"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
    >
      <PaymentErrorIcon />

      <Div fontFamily="PretendardBold" fontSize="1.3rem" mb="2rem">
        주문을 실패하였습니다.
      </Div>

      <OrderButton onClick={() => navigate("/")} isAll>
        홈으로 가기
      </OrderButton>
    </Div>
  );
};

export default PaymentFailPage;
