import styled from "styled-components";

export const ModalContainer = styled.div<{ isMini?: boolean }>`
  /* 모달창 크기 */
  width: ${(props) => (props.isMini ? "30vw" : "90vw")};
  height: ${(props) => (props.isMini ? "50vh" : "90vh")};

  /* 최상단 위치 */
  z-index: 999;

  /* 중앙 배치 */
  /* top, bottom, left, right 는 브라우저 기준으로 작동한다. */
  /* translate는 본인의 크기 기준으로 작동한다. */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  /* 모달창 디자인 */
  background-color: #fff;
  border: none;
  box-sizing: border-box;
  padding: 2rem 2rem;
  overflow-y: scroll;
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */

  /* ( 크롬, 사파리, 오페라, 엣지 ) 동작 */
  &::-webkit-scrollbar {
    display: none;
  }

  //media query 스타일이 1073px미만일때 적용되게 됩니다.
  @media screen and (max-width: 1023px) {
    width: 95%;
    height: 95%;
    padding: 2rem 1rem;
  }
`;
