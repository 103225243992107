import styled from "styled-components";

export const MainIntroductionBackground = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0 12vw;

  display: flex;
  justify-content: space-between;
  align-items: center;

  //media query 스타일이 1073px미만일때 적용되게 됩니다.
  @media screen and (max-width: 1023px) {
    flex-direction: column;
    padding: 0 3vw;
    height: auto;
  }
`;
