import React, { useState } from "react";
import { HeaderMobileContainer } from "~/components/atoms/header/mobile/MobileHeaderContainer";
import { FiUser } from "react-icons/fi";
import { BiShoppingBag } from "react-icons/bi";
import { HiOutlineMenu } from "react-icons/hi";
import MobileHeaderMenu from "./MobileHeaderMenu";
import { useNavigate } from "react-router-dom";
import MobileAccountHeaderMenu from "./MobileAccountHeaderMenu";
import { getCookie } from "~/utils/cookie";

const MobileHeader = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAccountOpen, setIsAccountOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <HeaderMobileContainer>
        <FiUser
          size="1.5rem"
          style={{ marginRight: "0.5rem" }}
          onClick={() => setIsAccountOpen(!isAccountOpen)}
          color={"#000"}
        />
        {getCookie("access_token") && (
          <BiShoppingBag
            size="1.5rem"
            style={{ marginRight: "0.5rem" }}
            onClick={() => navigate("/cart")}
            color={"#000"}
          />
        )}

        <HiOutlineMenu
          size="1.5rem"
          onClick={() => setIsOpen(!isOpen)}
          color={"#000"}
        />
      </HeaderMobileContainer>
      <MobileAccountHeaderMenu
        isOpen={isAccountOpen}
        setIsOpen={setIsAccountOpen}
      />
      <MobileHeaderMenu isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export default MobileHeader;
