import React from "react";
import { KakaoButtonContainer } from "~/components/atoms/account/kakao/KakaoButtonContainer";
import { KakaoIcon } from "~/components/atoms/account/kakao/KakaoIcon";

interface KakaoLoginButtonProps {
  onClick?: () => void;
}

const KakaoLoginButton = ({ onClick }: KakaoLoginButtonProps) => {
  return (
    <KakaoButtonContainer onClick={onClick}>
      <KakaoIcon />
      <div>카카오 계정으로 로그인</div>
    </KakaoButtonContainer>
  );
};

export default KakaoLoginButton;
