import styled from "styled-components";

export const MyPageTableData = styled.div`
  width: 100%;
  height: 2.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 8fr 2fr 2fr;
  justify-items: center;
  align-items: center;
  border-bottom: 1px solid #dbdbdb;
  cursor: pointer;
`;
