import axios from "axios";
import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { kakaoLoginApi } from "~/apis/user/kakao-login-api";
import { naverLoginApi } from "~/apis/user/naver-login-api";
import { loginApi } from "~/apis/user/login-api";
import { signupApi } from "~/apis/user/signup-api";
import { getCookie, removeCookie, setCookie } from "~/utils/cookie";

interface LoginuserType {
  email: string;
  password: string;
}

const AuthContext = createContext({
  user: {
    id: 0,
    email: "",
    auth: false,
  },
  login: (useData: LoginuserType) => {},
  logout: () => {},
  kakaoLogin: (code: string) => {},
  naverLogin: (code: string) => {},
  isAuthenticated: false,
});

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    id: 0,
    email: "",
    auth: false,
  });

  const login = (useData: LoginuserType) => {
    loginApi(useData.email, useData.password)
      .then((res) => {
        // 토큰 정보 저장하기
        const token = res.headers.authorization.replace("Bearer ", "");
        setCookie("access_token", token);
        setUser({
          id: res.data.id,
          email: res.data.email,
          auth: true,
        });
        navigate("/");
      })
      .catch((err) => {
        alert(err.response.data.msg);
        return false;
      });
  };

  const logout = () => {
    removeCookie("access_token");
    window.location.reload();
  };

  const kakaoLogin = (code: string) => {
    kakaoLoginApi(code)
      .then((res) => {
        // 토큰 정보 저장하기
        const token = res.headers.authorization.replace("Bearer ", "");
        setCookie("access_token", token);
        setUser({
          id: res.data.id,
          email: res.data.email,
          auth: true,
        });
        navigate("/");
      })
      .catch((err) => {
        alert(err.response.data.msg);
        return false;
      });
  };

  const naverLogin = (code: string) => {
    naverLoginApi(code)
      .then((res) => {
        // 토큰 정보 저장하기
        const token = res.headers.authorization.replace("Bearer ", "");
        setCookie("access_token", token);
        setUser({
          id: res.data.id,
          email: res.data.email,
          auth: true,
        });
        navigate("/");
      })
      .catch((err) => {
        alert(err.response.data.msg);
        return false;
      });
  };

  const isAuthenticated = getCookie("access_token") ? true : false;
  return (
    <AuthContext.Provider
      value={{ user, login, logout, isAuthenticated, kakaoLogin, naverLogin }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}
