import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useResetRecoilState } from "recoil";
import { fabricSelectState } from "~/store/fabric-select-state";

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const resetFabricState = useResetRecoilState(fabricSelectState);

  useEffect(() => {
    window.scrollTo(0, 0);
    resetFabricState();
  }, [pathname]);

  return null;
}
