import styled from "styled-components";

export const NoticeItemBox = styled.div`
  width: 100%;
  height: 5rem;
  border-bottom: 1px solid #dbdbdb;
  font-family: "PretendardRegular";
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
