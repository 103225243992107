import React from "react";
import { useMediaQuery } from "react-responsive";
import { FabricDescription } from "~/components/atoms/fabric/FabricDescription";
import { FabricTextContainer } from "~/components/atoms/fabric/FabricTextContainer";
import { FabricTitle } from "~/components/atoms/fabric/FabricTitle";

interface FabricTextProps {
  title: string;
  description: string;
}

const FabricText = ({ title, description }: FabricTextProps) => {
  const isTablet = useMediaQuery({
    query: "(max-width:1023px)",
  });
  return (
    <FabricTextContainer>
      <FabricTitle>{title}</FabricTitle>
      {<FabricDescription>{description}</FabricDescription>}
    </FabricTextContainer>
  );
};

export default FabricText;
