import React from "react";
import Div from "~/components/atoms/div";
import { MainIntroductionDescription } from "~/components/atoms/main/introduction/MainIntroductionDescription";
import { MainIntroductionTitle } from "~/components/atoms/main/introduction/MainIntroductionTitle";

const MainIntroductionText = () => {
  return (
    <Div display="flex" flexDirection="column">
      <MainIntroductionTitle>"남성 정장 맞춤의 모든 것"</MainIntroductionTitle>
      <MainIntroductionDescription>
        ( 지금도 매장 방문 하셔서 맞추시나요 ?) <br />
        안녕하세요 저희 온라인 맞춤정장 마므레 사이트에 방문해 주신 것
        감사합니다 <br />
        마므레 온라인 맞춤은 고객이 직접 방문 하지 않아도 어떻게 맞출수있을 까?
        <br />
        이런 생각 속에서 꿈을 준비한 45년 세월을 패턴과 모든 고객의 데이터 분석
        하고 적용 해서 만든 마므레의 노하우를 <br />
        가지고 온라인 사업을 시작합니다.
        <br />
        <br />
        사무실이나 집에서 온라인 으로 직접 내 사이즈 입력 하고 디자인 선택
        하시면 나만의 맞춤 복을 맞추어드립니다 <br />
        기성복 입어 보시면 가슴은 맞는데 어깨 소매기장 상의기장 안맞아서 수선
        하시죠. <br />
        (예 100 입으시면 키 170 소매기장 23 허리 35 기준으로 제작
        많이합니다)(키) (허리둘레) 따라 치수가 다 다릅니다.
        <br />( 등굽은 체형 )(가슴 나온체형 )이면 불편합니다 <br />
        <br />
        저희 마므레 온라인 맞춤 에서 (고객의 한분한분 치수를 직접 채촌한것처럼
        정확히 할 수 있습니다 (나이에 따라 핏이 다름)
        <br /> (키) (나이) (잘맞는 가슴둘레 사이즈 90,95,100,105,110,115,120등)
        <br />
        <br />( 가슴둘레 사이즈 에서 허리둘레 부분 (맞다 )(작다)(크다) 선택
        하시면 됩니다 (그리고 고객 사진 (앞사진)( 옆사진) (뒤 사진) 주시면
        <br />
        고객의 모든 사이즈 뽑아서 고객의 사이즈로 패턴을 그리고 제단합니다.
        <br />
        (상의기장,어깨, 좌우 소매기장 체형 분석 해서) 제단합니다 고객 (치수보관
        아닌 패턴을 보관 합니다 )
      </MainIntroductionDescription>
    </Div>
  );
};

export default MainIntroductionText;
