import styled from "styled-components";

export const MainIntroductionTitle = styled.div`
  font-family: "PretendardBold";
  color: #000;
  font-size: 2rem;
  margin-bottom: 1.5rem;

  //media query 스타일이 1073px미만일때 적용되게 됩니다.
  @media screen and (max-width: 1023px) {
    text-align: center;
  }
`;
