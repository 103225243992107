import React from "react";
import { useRecoilValue } from "recoil";
import { DetailPriceText } from "~/components/atoms/detail/price/DetailPriceText";
import Div from "~/components/atoms/div";
import { fabricSelectState } from "~/store/fabric-select-state";

interface DetailPriceProps {
  price: number;
}

const DetailPrice = ({ price }: DetailPriceProps) => {
  return (
    <Div display="flex" flexDirection="column" my="3rem">
      <DetailPriceText>총 금액</DetailPriceText>
      <DetailPriceText isPrice>₩ {price.toLocaleString()}</DetailPriceText>
    </Div>
  );
};

export default DetailPrice;
