import styled from "styled-components";

export const PaymentAddressStyledInput = styled.div`
  height: 2.5rem;
  border-radius: 0.5rem;
  border: 1px solid #dbdbdb;
  padding: 0 1rem;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  cursor: pointer;
  font-family: "PretendardRegular";
  font-size: 1.1rem;
  color: #767676;
`;
