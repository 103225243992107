import styled from "styled-components";

export const DetailPreviewGridContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  gap: 0.65rem;
  margin-bottom: 1.5rem;

  @media screen and (max-width: 1023px) {
    grid-template-columns: 1fr 1fr;
    gap: 0.65rem;
  }
`;
