import React from "react";
import { AccountHintText } from "~/components/atoms/account/AccountHintText";
import Div from "~/components/atoms/div";
import KakaoLoginButton from "~/components/molecules/login/KakaoLoginButton";
import NaverLoginButton from "~/components/molecules/login/NaverLoginButton";

const SocialLoginBox = () => {
  const kakaoLogin = () => {
    const clientId = process.env.REACT_APP_KAKAO_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_KAKAO_REDIRECT_URI;

    window.open(
      `https://kauth.kakao.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code`,
      "_self"
    );
  };

  const naverLogin = () => {
    const clientId = process.env.REACT_APP_NAVER_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_NAVER_REDIRECT_URI;
    const state = Math.random().toString(36);

    window.open(
      `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}$state=${state}`,
      "_self"
    );
  };

  return (
    <Div mb="2rem">
      <AccountHintText>간편로그인</AccountHintText>
      <KakaoLoginButton onClick={kakaoLogin} />
      <NaverLoginButton onClick={naverLogin} />
    </Div>
  );
};

export default SocialLoginBox;
