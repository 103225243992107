import React, { useEffect, useState } from "react";
import { FabricContainer } from "~/components/atoms/fabric/FabricContainer";
import { FabricModalSubTitle } from "~/components/atoms/fabric/modal/FabricModalSubTitle";
import { FabricModalTitle } from "~/components/atoms/fabric/modal/FabricModalTitle";
import Modal from "~/components/molecules/modal/Modal";
import FabricItem from "~/components/organisms/fabric/FabricItem";
import { cheapFabric, expensiveFabric, shirtsFabric } from "~/utils/fabric";
import FabricColorModal from "./FabricColorModal";
import { useRecoilState } from "recoil";
import { fabricModalInfoState } from "~/store/fabric-color-state";
import { getFabricListApi } from "~/apis/fabric/get-fabric-list-api";
import { FabricType } from "~/types/fabric-type";
import { fabricSelectState } from "~/store/fabric-select-state";
import { useMediaQuery } from "react-responsive";
import { getFabricByCategory } from "~/apis/fabric/get-fabric-by-category";
import { currentCategoryState } from "~/store/current-category-state";

interface FabricModalProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isShirts?: boolean;
}

const FabricModal = ({
  isModalOpen,
  setIsModalOpen,
  isShirts,
}: FabricModalProps) => {
  const [isColorModalOpen, setIsColorModalOpen] = useState(false);

  const [fabricSelect, setFabricSelect] = useRecoilState(fabricSelectState);
  const [currentCategory, setCurrentCategory] =
    useRecoilState(currentCategoryState);
  const [fabrics, setFabrics] = useState<FabricType[]>([]);
  const [fabricModalInfo, setFabricModalInfo] =
    useRecoilState(fabricModalInfoState);

  useEffect(() => {
    getFabricByCategory(currentCategory)
      .then((res) => {
        setFabrics(res.data.list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [currentCategory]);

  function handleDetailButtonClick(
    id: number,
    name: string,
    description: string,
    image: string
  ) {
    setIsColorModalOpen(true);
    setFabricModalInfo({
      id,
      name,
      description,
    });
    setFabricSelect({
      ...fabricSelect,
      fabricId: id,
      name: name,
      fabricImage: image,
    });
  }

  return (
    <>
      <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
        <FabricModalTitle>원단 선택</FabricModalTitle>
        <FabricModalSubTitle>
          원하시는 원단과 번호를 선택해주세요
        </FabricModalSubTitle>

        {/* <FabricModalTitle isSub>30~50만 원대 원단</FabricModalTitle> */}
        <FabricContainer>
          {fabrics.map((fabric: any, index) => (
            <FabricItem
              key={index}
              src={fabric.image}
              title={fabric.name}
              description={fabric.description}
              onClick={() =>
                handleDetailButtonClick(
                  fabric.id,
                  fabric.name,
                  fabric.description,
                  fabric.image
                )
              }
            />
          ))}
        </FabricContainer>

        {/* 셔츠가 원단을 보여줌 */}
        {/* {isShirts && (
          <FabricContainer>
            {shirtsFabric.map((fabric, index) => (
              <FabricItem
                key={index}
                src={fabric.image}
                title={fabric.title}
                description={fabric.description}
                onClick={() => {
                  setIsColorModalOpen(true);
                  console.log("modal button click");
                }}
              />
            ))}
          </FabricContainer>
        )} */}
      </Modal>

      <FabricColorModal
        isModalOpen={isColorModalOpen}
        setIsModalOpen={setIsColorModalOpen}
        parentIsModalOpen={setIsModalOpen}
      />
    </>
  );
};

export default FabricModal;
