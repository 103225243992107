import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { DetailFabricSelectTitle } from "~/components/atoms/detail/DetailFabricSelectTitle";
import { DetailStyledInput } from "~/components/atoms/detail/input/DetailStyledInput";
import { DetailSelectBoxTitle } from "~/components/atoms/detail/select/DetailSelectBoxTitle";
import Div from "~/components/atoms/div";
import { ModalContainer } from "~/components/atoms/modal/ModalContainer";
import { ModalOverlay } from "~/components/atoms/modal/ModalOvelay";
import FabricModal from "~/components/templates/modal/FabricModal";
import { fabricSelectState } from "~/store/fabric-select-state";

interface DetailFabricSelectProps {
  isShirts?: boolean;
}

const DetailFabricSelect = ({ isShirts }: DetailFabricSelectProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fabricSelect, setFabricSelect] = useRecoilState(fabricSelectState);

  return (
    <>
      <Div display="flex" flexDirection="column" mb="1rem">
        <Div display="flex" alignItems="center">
          <DetailSelectBoxTitle>원단선택</DetailSelectBoxTitle>
          <DetailFabricSelectTitle onClick={() => setIsModalOpen(true)}>
            원단 선택하기
          </DetailFabricSelectTitle>
        </Div>
        <DetailStyledInput
          placeholder="원단번호"
          value={`${fabricSelect.name}, ${fabricSelect.code}`}
          readOnly
        />
      </Div>

      <FabricModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isShirts={isShirts}
      />
    </>
  );
};

export default DetailFabricSelect;
