import { atom } from "recoil";

interface FabricModalInfoType {
  id: number;
  name: string;
  description: string;
}

export const fabricModalInfoState = atom<FabricModalInfoType>({
  key: "fabricModalInfoState",
  default: {
    id: 0,
    name: "",
    description: "",
  },
});
