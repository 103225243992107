import React from "react";
import { useNavigate } from "react-router-dom";
import Div from "~/components/atoms/div";
import { BlogIcon } from "~/components/atoms/header/BlogIcon";
import { HeaderMenuAccountItem } from "~/components/atoms/header/HeaderMenuAccountItem";
import { HeaderMenuItemContainer } from "~/components/atoms/header/HeaderMenuItemContainer";
import { useAuth } from "~/contexts/AuthContext";
import { getCookie, removeCookie } from "~/utils/cookie";

const HeaderAccountItem = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const logoutItems = [
    {
      name: "로그인",
      onclick: () => navigate("/login"),
      isSelected: currentLocationCheck("login"),
    },
    {
      name: "회원가입",
      onclick: () => navigate("/signup"),
      isSelected: currentLocationCheck("signup"),
    },
  ];
  const loginItems = [
    {
      name: "로그아웃",
      onclick: () => logout(),
      isSelected: false,
    },
    {
      name: "마이페이지",
      onclick: () => navigate("/mypage"),
      isSelected: currentLocationCheck("mypage"),
    },
    {
      name: "장바구니",
      onclick: () => navigate("/cart"),
      isSelected: currentLocationCheck("cart"),
    },
  ];

  function currentLocationCheck(path: string, path2?: string, path3?: string) {
    const currentLocation = window.location.pathname;
    if (
      currentLocation.includes(path) ||
      (path2 && currentLocation.includes(path2)) ||
      (path3 && currentLocation.includes(path3))
    ) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <HeaderMenuItemContainer width={getCookie("access_token") ? "auto" : "60%"}>
      {!getCookie("access_token") &&
        logoutItems.map((item) => (
          <HeaderMenuAccountItem
            key={item.name}
            onClick={item.onclick}
            isSelected={item.isSelected}
            isMain={false}
          >
            {item.name}
          </HeaderMenuAccountItem>
        ))}

      {getCookie("access_token") &&
        loginItems.map((item) => (
          <HeaderMenuAccountItem
            key={item.name}
            onClick={item.onclick}
            isSelected={item.isSelected}
            isMain={false}
          >
            {item.name}
          </HeaderMenuAccountItem>
        ))}

      <Div
        width="100%"
        fontSize="0.8rem"
        display="flex"
        justifyContent="center"
        alignItems="center"
        fontFamily="PretendardBold"
        color="black"
        ml="10px"
        onClick={() =>
          window.open("https://blog.naver.com/digirie11", "_blank")
        }
        style={{ cursor: "pointer" }}
      >
        <BlogIcon />
        <div>블로그</div>
      </Div>
    </HeaderMenuItemContainer>
  );
};

export default HeaderAccountItem;
