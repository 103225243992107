import styled, { css } from "styled-components";

export const OrderButton = styled.button<{ isAll?: boolean }>`
  width: 12.3125rem;
  height: 3.5rem;
  border: ${(props) => (props.isAll ? "none" : "1px solid #d69457")};
  border-radius: 0.5rem;
  background-color: ${(props) => (props.isAll ? "#d69457" : "#fff")};
  cursor: pointer;
  font-family: "PretendardBold";
  font-size: 1.1rem;
  color: ${(props) => (props.isAll ? "#fff" : "#000")};

  ${(props) =>
    !props.isAll &&
    css`
      margin-right: 1rem;
    `}
`;
