import React, { useEffect, useState } from "react";
import { MobileMenu } from "~/components/atoms/header/mobile/MobileMenu";
import { MobileMenubar } from "~/components/atoms/header/mobile/MobileMenuBar";
import { MobileMenubarWrapper } from "~/components/atoms/header/mobile/MobileMenuBarWrapper";
import { IoIosArrowDown } from "react-icons/io";
import { MobileSubMenu } from "~/components/atoms/header/mobile/MobileSubMenu";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { currentCategoryState } from "~/store/current-category-state";

interface MobileHeaderMenuProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MobileHeaderMenu = ({ isOpen, setIsOpen }: MobileHeaderMenuProps) => {
  const navigate = useNavigate();
  const [isOnlineMallOpen, setIsOnlineMallOpen] = useState(false);
  const [isShirtsOpen, setIsShirtsOpen] = useState(false);
  const [isEtcOpen, setIsEtcOpen] = useState(false);

  const onlineMallMenus = [
    {
      title: "세밀한 상의",
      link: "/detail/top",
    },
    {
      title: "간편한 상의",
      link: "/simple/top",
    },
    {
      title: "세밀한 하의",
      link: "/detail/bottom",
    },
    {
      title: "간편한 하의",
      link: "/simple/bottom",
    },
    {
      title: "베스트",
      link: "/simple/vest",
    },
  ];

  const shirtsMenus = [
    {
      title: "세밀한 셔츠",
      link: "/detail/shirts",
    },
    {
      title: "간편한 셔츠",
      link: "/simple/shirts",
    },
  ];

  const etcMenus = [
    {
      title: "넥타이",
      link: "/etc/necktie",
    },
    {
      title: "핀",
      link: "/etc/pin",
    },
    {
      title: "스트랩",
      link: "/etc/strap",
    },
  ];

  useEffect(() => {
    setIsOpen(false);
    setIsEtcOpen(false);
    setIsOnlineMallOpen(false);
    setIsShirtsOpen(false);
  }, [window.location.pathname]);

  return (
    <MobileMenubar isOpen={isOpen}>
      <MobileMenubarWrapper>
        <MobileMenu onClick={() => navigate("/landing")}>마므레</MobileMenu>

        <MobileMenu onClick={() => setIsOnlineMallOpen(!isOnlineMallOpen)}>
          온라인몰
          <IoIosArrowDown />
        </MobileMenu>

        <MobileSubMenu isOpen={isOnlineMallOpen}>
          {onlineMallMenus.map((item, index) => (
            <div key={index} onClick={() => navigate(item.link)}>
              {item.title}
            </div>
          ))}
        </MobileSubMenu>

        <MobileMenu onClick={() => navigate("/simple/coat")}>코트</MobileMenu>
        <MobileMenu onClick={() => setIsShirtsOpen(!isShirtsOpen)}>
          셔츠 <IoIosArrowDown />
        </MobileMenu>

        <MobileSubMenu isOpen={isShirtsOpen}>
          {shirtsMenus.map((item, index) => (
            <div key={index} onClick={() => navigate(item.link)}>
              {item.title}
            </div>
          ))}
        </MobileSubMenu>

        <MobileMenu onClick={() => setIsEtcOpen(!isEtcOpen)}>
          기타 액세서리 <IoIosArrowDown />
        </MobileMenu>

        <MobileSubMenu isOpen={isEtcOpen}>
          {etcMenus.map((item, index) => (
            <div key={index} onClick={() => navigate(item.link)}>
              {item.title}
            </div>
          ))}
        </MobileSubMenu>

        <MobileMenu onClick={() => navigate("/notice")}>게시판</MobileMenu>
        <MobileMenu
          onClick={() =>
            window.open("https://blog.naver.com/digirie11", "_blank")
          }
        >
          블로그
        </MobileMenu>
      </MobileMenubarWrapper>
    </MobileMenubar>
  );
};

export default MobileHeaderMenu;
