import styled, { css } from "styled-components";

export const PaymentItemContainer = styled.div<{
  isProduct?: boolean;
  isDetail?: boolean;
}>`
  width: ${(props) => (props.isProduct ? "80%" : "100%")};
  height: ${(props) => (props.isProduct ? "70%" : "100%")};
  border-radius: 0.5rem;
  border: 2px solid #dbdbdb;
  padding: 2rem 2rem;
  margin-bottom: 2rem;
  //media query 스타일이 1073px미만일때 적용되게 됩니다.

  ${(props) =>
    props.isDetail &&
    css`
      border: none;
      background-color: #f4f4f4;
    `}

  @media screen and (max-width: 1023px) {
    width: 100%;
    border: none;
    padding: 2rem 0;
  }
`;
