import styled from "styled-components";

export const MobileSubMenu = styled.div<{ isOpen: boolean }>`
  font-family: "PretendardRegular";
  font-size: 0.8rem;
  color: #242424;
  padding: 1rem 0;
  border-bottom: 1px solid #d9d9d9;
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  flex-direction: column;
  gap: 10px;
  box-sizing: border-box;
  padding-left: 14px;
`;
