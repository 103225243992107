import styled from "styled-components";

export const MobileMenuButtonContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0 5vw;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.46rem;
  margin-bottom: 1.5rem;
`;
