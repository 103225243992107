import React, { useEffect, useState } from "react";
import { MobileMenu } from "~/components/atoms/header/mobile/MobileMenu";
import { MobileMenubar } from "~/components/atoms/header/mobile/MobileMenuBar";
import { MobileMenubarWrapper } from "~/components/atoms/header/mobile/MobileMenuBarWrapper";
import { IoIosArrowDown } from "react-icons/io";
import { MobileSubMenu } from "~/components/atoms/header/mobile/MobileSubMenu";
import { useNavigate } from "react-router-dom";
import { getCookie } from "~/utils/cookie";
import { useAuth } from "~/contexts/AuthContext";

interface MobileHeaderMenuProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MobileAccountHeaderMenu = ({
  isOpen,
  setIsOpen,
}: MobileHeaderMenuProps) => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  useEffect(() => {
    setIsOpen(false);
  }, [window.location.pathname]);

  return (
    <MobileMenubar isOpen={isOpen}>
      <MobileMenubarWrapper>
        {!getCookie("access_token") && (
          <>
            <MobileMenu onClick={() => navigate("/login")}>로그인</MobileMenu>
            <MobileMenu onClick={() => navigate("/signup")}>
              회원가입
            </MobileMenu>
          </>
        )}

        {getCookie("access_token") && (
          <>
            <MobileMenu onClick={() => logout()}>로그아웃</MobileMenu>
            <MobileMenu onClick={() => navigate("/mypage")}>
              마이페이지
            </MobileMenu>
          </>
        )}
      </MobileMenubarWrapper>
    </MobileMenubar>
  );
};

export default MobileAccountHeaderMenu;
