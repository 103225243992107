import styled from "styled-components";

export const MobileMenu = styled.div`
  font-family: "PretendardRegular";
  font-size: 1rem;
  color: #242424;
  padding: 1rem 0;
  /* border-top: 1px solid #d9d9d9; */
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
